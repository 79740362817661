import { IConfig } from 'src/common/interfaces/IConfig'

// These values {#...#} are replaced in the release pipeline
// If they're not replaced then the local test values are used
let envValue = '#{Omgeving}#'
if (envValue.startsWith('#{')) {
  envValue = 'sys'
}
console.log(`Env: ${envValue}`)

let apiHostValue = '#{ApiHost}#'
if (apiHostValue.startsWith('#{')) {
  // apiHostValue = 'https://localhost:44335'
  apiHostValue =
    'https://rru-tc-veilcontractbeheerservice-api.commstaging.royalfloraholland.com'
}

let redirectUriValue = '#{OktaRedirectUri}#'
if (redirectUriValue.startsWith('#{')) {
  redirectUriValue = 'http://localhost:3000/callback'
}

let clientIdValue = '#{OktaClientId}#'
if (clientIdValue.startsWith('#{')) {
  clientIdValue = '0oa3v6i6ke6CUTMxj0x7' //rru-veilcontractbeheer-dev
}

let domainValue = '#{OktaDomain}#'
if (domainValue.startsWith('#{')) {
  domainValue = 'https://identity-test.royalfloraholland.com/oauth2/default'
}

let issuerValue = '#{OktaIssuer}#'
if (issuerValue.startsWith('#{')) {
  issuerValue = 'https://identity-test.royalfloraholland.com/oauth2/default'
}

export const Config: IConfig = {
  env: envValue,
  api: {
    host: apiHostValue,
    path: '',
    apiVersion: '/odata/v1',
  },
  okta: {
    clientId: clientIdValue,
    domain: domainValue,
    issuer: issuerValue,
    redirectUri: redirectUriValue,
    scopes: [
      'openid',
      'profile',
      'email',
      `rru-veilcontractbeheer-api-${envValue}`,
    ],
    pkce: true,
  },
}
