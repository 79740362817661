import { Box, Grid, Typography, styled } from '@mui/material'

import { ThemeConfig } from 'src/common/config/SpacingConfig'

export const StyledGridCard = styled(Grid)(() => ({
  padding: ThemeConfig.spacing.sm,
  paddingTop: '0 !important',
  paddingBottom: '0 !important',
}))

export const StyledGridSmall = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    display: 'block',
  },
}))

export const StyledBoxLoader = styled(Box)<{ isCard: boolean }>(
  ({ theme, isCard }) => ({
    backgroundColor: 'white',
    padding: theme.spacing(ThemeConfig.spacing.sm),
    minHeight: '225px',
    display: !isCard ? 'flex' : '',
    justifyContent: !isCard ? 'center' : '',
    alignItems: !isCard ? 'center' : '',
  })
)

export const StyledRfhTypographyText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  padding: 0,
  justifyContent: 'space-between',
  margin: `${theme.spacing(ThemeConfig.spacing.xs)} 0`,
}))

export const StyledGridButtonContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(ThemeConfig.spacing.sm),
  marginBottom: theme.spacing(ThemeConfig.spacing.xl),
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.only('xs')]: {
    alignItems: 'stretch',
    flexDirection: 'column',
    justifyContent: 'end',
  },
}))
