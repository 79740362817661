import { useTranslation } from 'react-i18next'

import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  selectClasses,
} from '@mui/material'
import theme from '@rfh-core/theme'

import { ThemeConfig } from 'src/common/config'

type Props = {
  onChange?: (event: SelectChangeEvent<string>) => void
  labelVisible?: boolean
  label: string
  required?: boolean
  disabled?: boolean
  inputValue: boolean | undefined
  fieldname: string
  sx?: SxProps
}

export default function LabelBooleanDropdown({
  required,
  disabled,
  onChange,
  labelVisible,
  label,
  inputValue,
  fieldname,
  sx,
}: Readonly<Props>): JSX.Element {
  const { t } = useTranslation()
  const getOptions = () => [
    <MenuItem sx={{ minWidth: 160 }} key={''} value={undefined}>
      {t('common.notApplicable')}
    </MenuItem>,
    <MenuItem sx={{ minWidth: 160 }} key={'true'} value={'true'}>
      {t('common.yes')}
    </MenuItem>,
    <MenuItem sx={{ minWidth: 160 }} key={'false'} value={'false'}>
      {t('common.no')}
    </MenuItem>,
  ]

  return (
    <FormControl
      fullWidth
      sx={{
        margin: 0,
      }}
    >
      {labelVisible && (
        <FormLabel
          sx={{
            fontFamily: 'halyard-display',
            fontWeight: 'bold',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            color: theme.rfhColors.black,
          }}
          htmlFor={`id-${fieldname}`}
        >
          {label}
        </FormLabel>
      )}
      <Select
        required={required}
        value={inputValue !== undefined ? String(inputValue) : ''}
        defaultValue=''
        onChange={onChange}
        disabled={disabled}
        id={`id-${fieldname}`}
        name={fieldname}
        aria-label={label}
        autoWidth
        sx={{
          minWidth: 160,
          width: '100%',
          bgcolor: theme.rfhColors.white,
          mt: ThemeConfig.spacing.sm,
          [`& .${selectClasses.select}`]: {
            pointerEvents: 'auto',
          },
          ...sx,
        }}
      >
        {getOptions()}
      </Select>
    </FormControl>
  )
}
