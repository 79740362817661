import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { IconButton } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium'
import { DeleteIcon, EditIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import CustomDataGrid from 'src/common/components/CustomDataGrid'
import { IInkoperView } from 'src/common/services/client'
import { useUserStore } from 'src/common/stores/UserStore'
import i18n from 'src/i18n/index'

import { purchasersColumns } from '../constants'
import { DeletePurchaserDialog } from './DeletePurchaserDialog'

type PurchasersGridProps = {
  isLoading: boolean
  refresh: () => void
  rows: IInkoperView[]
  totalCount: number
}

export const PurchasersGrid: FC<PurchasersGridProps> = ({
  isLoading,
  refresh,
  rows,
  totalCount,
}) => {
  const { t } = useTranslation()
  const { user } = useUserStore()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [idToBeDeleted, setIdToBeDeleted] = useState<number>(0)

  const getRowId = useCallback((row: IInkoperView) => row.inkoperID, [])

  const openDeleteDialog = (value: IInkoperView) => {
    setOpenDeleteModal(true)
    setIdToBeDeleted(value.inkoperID)
  }

  const closeDeleteDialog = useCallback(() => {
    setOpenDeleteModal(false)
    setIdToBeDeleted(0)
  }, [])

  const getColumns = useCallback((): GridColDef[] => {
    const staticColumns = [...purchasersColumns]
    if (user.isContractBeheerder || user.isApplicationManager) {
      staticColumns.push({
        field: 'bewerken',
        headerName: t('common.edit'),
        headerAlign: 'center',
        align: 'center',
        disableColumnMenu: true,
        hideable: false,
        sortable: false,
        disableExport: true,
        renderHeader: () => (
          <EditIcon
            sx={{
              color: `${theme.rfhColors.white}`,
              width: '20px',
              height: '20px',
            }}
          />
        ),
        renderCell: (params: GridRenderCellParams<any, IInkoperView>) =>
          new Date(params.row.vervallenDatumTijd) > new Date() ? (
            <IconButton
              component={Link}
              to={`/dashboard/purchasers/${params.row.inkoperID}`}
            >
              <EditIcon
                data-testid='edit'
                sx={{
                  cursor: 'pointer',
                  color: `${theme.rfhColors.grey[800]}`,
                  width: '20px',
                  height: '20px',
                }}
              />
            </IconButton>
          ) : (
            <EditIcon
              data-testid='edit'
              sx={{
                cursor: 'pointer',
                color: `${theme.rfhColors.grey[100]}`,
                width: '20px',
                height: '20px',
              }}
            />
          ),
        flex: 0.5,
      })
      staticColumns.push({
        field: 'delete',
        headerName: t('common.delete'),
        headerAlign: 'center',
        align: 'center',
        disableColumnMenu: true,
        type: 'actions',
        hideable: false,
        sortable: false,
        disableExport: true,
        renderHeader: () => (
          <DeleteIcon
            sx={{
              color: theme.rfhColors.white,
              width: '20px',
              height: '20px',
            }}
          />
        ),
        renderCell: (params: GridRenderCellParams) => (
          <IconButton onClick={() => openDeleteDialog(params.row)}>
            <DeleteIcon
              data-testid='delete'
              sx={{
                cursor: 'pointer',
                color: `${theme.rfhColors.grey[800]}`,
                width: '20px',
                height: '20px',
              }}
            />
          </IconButton>
        ),
        flex: 0.5,
      })
    }

    return staticColumns
  }, [t, user.isApplicationManager, user.isContractBeheerder])

  return (
    <>
      <CustomDataGrid
        columns={getColumns()}
        getRowId={getRowId}
        gridName={'purchasers'}
        gridProps={{
          export: {
            title: t('purchaser.purchasers'),
          },
          loading: isLoading,
          rowCount: totalCount,
        }}
        language={i18n.language}
        rows={rows}
      />
      <DeletePurchaserDialog
        idToBeDeleted={idToBeDeleted}
        onCancel={closeDeleteDialog}
        onClose={closeDeleteDialog}
        open={openDeleteModal}
        onRefresh={refresh}
      />
    </>
  )
}
