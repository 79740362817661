// eslint-disable-next-line
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Chip, Container, Grid, Stack, Typography } from '@mui/material'

import BooleanTag from 'src/common/components/BooleanTag'
import { Config } from 'src/common/config/Config'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { useUser } from 'src/common/providers/UserProvider'

import {
  StyledRfhTypographyContent,
  StyledRfhTypographyTitle,
} from './ProfilePage.styles'
import { OverRulling } from './components/OverRulling'

export const ProfilePage: FC = () => {
  /*
   * Hooks
   */
  const user = useUser()
  const { t } = useTranslation()
  /*
   * Methods
   */
  const showLoginAccount = () => {
    if (user.preferredUsername && user.sub) {
      return user.preferredUsername === user.sub ? (
        user.preferredUsername
      ) : (
        <>
          {user.preferredUsername}
          <Chip color='info' label={user.sub} />
        </>
      )
    }
    return t('common.loading')
  }

  /*
   * Render
   */
  return (
    <Container
      maxWidth='xl'
      sx={{
        padding: ThemeConfig.spacing.sm,
        minHeight: 'calc(100vh - 64px - 8px)',
      }}
    >
      <Grid item xs={12}>
        <Typography variant={'h2'} style={{ fontSize: '34px' }}>
          {t('common.titles.profilePage')}
        </Typography>
      </Grid>

      <Grid
        container
        sx={{
          marginTop: ThemeConfig.spacing.sm,
          marginBottom: ThemeConfig.spacing.sm,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: 'white',
            padding: ThemeConfig.spacing.sm,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyTitle variant='h4'>
                {t('profilePage.name')}
              </StyledRfhTypographyTitle>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyContent
                data-testid='name'
                variant='subtitle1'
              >
                {user.name ? user.name : t('common.loading')}
              </StyledRfhTypographyContent>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyTitle variant='h4'>
                {t('profilePage.account')}
              </StyledRfhTypographyTitle>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyContent
                data-testid='account'
                variant='subtitle1'
              >
                {showLoginAccount()}
              </StyledRfhTypographyContent>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyTitle variant='h4'>
                {t('profilePage.email')}
              </StyledRfhTypographyTitle>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledRfhTypographyContent
                data-testid='email'
                variant='subtitle1'
              >
                {user.email ? user.email : t('common.loading')}
              </StyledRfhTypographyContent>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Stack gap={3} flexDirection='row'>
        <Grid
          item
          flexDirection={'column'}
          width='100%'
          xs={user.isApplicationManager && Config.env !== 'prd' ? 6 : 12}
        >
          <Grid item>
            <Typography variant={'h2'} style={{ fontSize: '34px' }}>
              {t('profilePage.permissions')}
            </Typography>
          </Grid>

          <Grid
            container
            height={'100%'}
            sx={{
              marginTop: ThemeConfig.spacing.sm,
              marginBottom: ThemeConfig.spacing.sm,
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: 'white',
                padding: ThemeConfig.spacing.sm,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <StyledRfhTypographyTitle variant='h4'>
                    {t('profilePage.readOnlyRole')}
                  </StyledRfhTypographyTitle>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledRfhTypographyContent
                    data-testid='readOnly'
                    variant='subtitle1'
                  >
                    <BooleanTag value={user.isReadOnly} />
                  </StyledRfhTypographyContent>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <StyledRfhTypographyTitle variant='h4'>
                    {t('profilePage.applicationManagerRole')}
                  </StyledRfhTypographyTitle>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledRfhTypographyContent
                    data-testid='applicationManger'
                    variant='subtitle1'
                  >
                    <BooleanTag value={user.isApplicationManager} />
                  </StyledRfhTypographyContent>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <StyledRfhTypographyTitle variant='h4'>
                    {t('profilePage.accountManagementRole')}
                  </StyledRfhTypographyTitle>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledRfhTypographyContent
                    data-testid='isAccountManagement'
                    variant='subtitle1'
                  >
                    <BooleanTag value={user.isAccountManagement} />
                  </StyledRfhTypographyContent>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <StyledRfhTypographyTitle variant='h4'>
                    {t('profilePage.contractManagementRole')}
                  </StyledRfhTypographyTitle>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledRfhTypographyContent
                    data-testid='isContractBeheerder'
                    variant='subtitle1'
                  >
                    <BooleanTag value={user.isContractBeheerder} />
                  </StyledRfhTypographyContent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {Config.env !== 'prd'
          ? user.isApplicationManager && (
              <Grid item xs={6} flexDirection={'column'} width='100%'>
                <Grid item flexDirection={'row'}>
                  <Typography variant={'h2'} style={{ fontSize: '34px' }}>
                    {t('profilePage.overrulingTitle')}
                  </Typography>
                </Grid>
                <OverRulling />
              </Grid>
            )
          : null}
      </Stack>
    </Container>
  )
}
