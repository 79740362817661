import { ChangeEvent, FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material'

import { Entity, useUpdateEntity } from 'src/common/hooks'
import { IInkoperKlokView } from 'src/common/services/client'

import { byClockShortName } from '../lib'
import { usePurchaserStore } from '../stores'

type PurchaserClocksDialogProps = {
  open: boolean
  onClose: () => void
  onCancel: () => void
}

export const PurchaserClocksDialog: FC<PurchaserClocksDialogProps> = ({
  open,
  onClose: close,
  onCancel: cancel,
}) => {
  const { t } = useTranslation()
  const updateEntity = useUpdateEntity(Entity.IInkoperKlokView)
  const { purchaserClocks, setPurchaserClocks } = usePurchaserStore()

  const handleCheckboxClick = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const clock = purchaserClocks.find(
        c => c.klokNummer === Number(target.value)
      )
      const index = purchaserClocks.indexOf(clock)
      clock.actief = target.checked
      setPurchaserClocks([
        ...purchaserClocks.slice(0, index),
        clock,
        ...purchaserClocks.slice(index + 1),
      ])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [purchaserClocks]
  )

  const handleSubmit = useCallback(async () => {
    if (updateEntity.isLoading) return Promise.resolve()
    await updateEntity.mutateAsync({
      inkoperKlokken: purchaserClocks,
    })

    close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaserClocks])

  return (
    <Dialog
      open={open}
      onClose={cancel}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 700,
        },
      }}
    >
      <DialogContent>
        <Typography variant={'h5'} style={{ marginBottom: '16px' }}>
          {t('purchaser.manageClocks.title')}
        </Typography>
        <Typography variant={'body1'} style={{ marginBottom: '16px' }}>
          <Grid
            container
            spacing={2}
            display={'grid'}
            gridTemplateColumns={'1fr'}
            gridTemplateRows={'repeat(7, 1fr)'}
            gridAutoColumns={'1fr'}
            gridAutoFlow={'column'}
            mt={1}
          >
            {[...purchaserClocks]
              .sort(byClockShortName)
              .map((clock: IInkoperKlokView) => (
                <Grid item key={clock.klokNummer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={clock.klokNummer}
                        checked={clock.actief}
                        onChange={handleCheckboxClick}
                      />
                    }
                    label={clock.klokKorteNaam}
                    labelPlacement={'end'}
                  />
                </Grid>
              ))}
          </Grid>
        </Typography>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={cancel}
            sx={{
              paddingY: '3px',
            }}
          >
            Cancel
          </Button>
          <Button variant='contained' onClick={handleSubmit}>
            OK
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
