import { ADD_PATH } from 'src/common/constants'
import { UpsertMode, UpsertModeType } from 'src/common/lib'

export const determineUpsertMode = (
  rootPath: string,
  pathname: string
): UpsertModeType => {
  const paths = pathname.split('/')
  if (paths[2] !== rootPath || !paths[3]) return UpsertMode.void
  if (paths[3] === ADD_PATH) return UpsertMode.add
  if (Number(paths[3])) return UpsertMode.edit
  return UpsertMode.void
}

export const getParam = (
  rootPath: string,
  pathname: string
): number | undefined => {
  const paths = pathname.split('/')
  const upsertMode = determineUpsertMode(rootPath, pathname)
  if (upsertMode === UpsertMode.add) return 0
  if (upsertMode === UpsertMode.edit) return Number(paths[3])
  return undefined
}
