import { StrictMode } from 'react'
import { render } from 'react-dom'

import { LicenseInfo } from '@mui/x-data-grid-premium'

import App from './App'
import './i18n'
import './index.css'

LicenseInfo.setLicenseKey(
  'a280ebba5ccba0a333d797d9f6853fecTz02OTQxNSxFPTE3MTkzODc1MTg3OTksUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
)

if ('serviceWorker' in navigator) {
  caches.keys().then(function (cacheNames) {
    cacheNames.forEach(function (cacheName) {
      caches.delete(cacheName)
    })
  })
}

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)
