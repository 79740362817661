import { Suspense, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import { ELEMENT_HEIGHT } from 'src/common'
import ActionButton from 'src/common/components/ActionButton'
import ButtonsBlock from 'src/common/components/ButtonsBlock'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import {
  Entity,
  useAddEntity,
  useDeleteEntity,
  usePathname,
} from 'src/common/hooks'
import { IInkoperPlaat } from 'src/common/services/client'
import i18n from 'src/i18n/index'

import { calcMarginRight } from '../lib'
import { useCardRowsStore, usePurchaserStore } from '../stores'
import { CardRowDiff } from '../types'
import { AddPurchaserCardDialog } from './AddPurchaserCardDialog'
import PurchaserCardsGrid from './PurchaserCardsGrid'
import { PurchaserClocksDialog } from './PurchaserClocksDialog'

export default function PurchaserCardsAndClocks(): JSX.Element {
  const { t } = useTranslation()
  const addEntity = useAddEntity<IInkoperPlaat>(Entity.IInkoperPlaat)
  const deleteEntity = useDeleteEntity(Entity.IInkoperPlaat)
  const marginRight = calcMarginRight(i18n.language)
  const [busyUpdating, setBusyUpdating] = useState<boolean>(false)
  const {
    getCardsByPlateNumber,
    isPurchaserDirty,
    setMustRefetchPurchaserCards,
    userId,
  } = usePurchaserStore()
  const { upsertMode } = usePathname('purchasers')
  const { cardRows, getCardRowsDiffs, areCardRowsDirty } = useCardRowsStore()
  const [openAdd, setOpenAdd] = useState<boolean>(false)
  const [openClocks, setOpenClocks] = useState<boolean>(false)

  const openAddDialog = useCallback(() => setOpenAdd(true), [])
  const openClocksDialog = useCallback(() => setOpenClocks(true), [])
  const closeAddDialog = useCallback(() => {
    setOpenAdd(false)
  }, [])
  const closeClocksDialog = useCallback(() => {
    setOpenClocks(false)
  }, [])

  const handleSubmitCardRows = useCallback(async () => {
    if (busyUpdating || addEntity.isLoading || deleteEntity.isLoading) {
      return Promise.resolve()
    }

    try {
      setBusyUpdating(true)
      /** how to update
       *  loop over cardRows, for each:
       *     - get all buyers cards views for the current koperplaat number
       *     - compare cardRow and its oldCardRow qua vistigingen, for each diff:
       *         - if added, ADD view
       *         - if deleted, DELETE view
       */
      const diffs: CardRowDiff[] = getCardRowsDiffs()
      const promises: (Promise<IInkoperPlaat> | Promise<void>)[] = []
      diffs.forEach(diff => {
        let promise
        if (diff.action === 'DELETE') {
          const plate = getCardsByPlateNumber(
            diff.koperPlaatNummer,
            diff.vestigingCode
          )[0]
          promise = deleteEntity.mutateAsync(plate.inkoperPlaatID)
        } else {
          const inkoperPlaat: IInkoperPlaat = {
            inkoperIdentificatie: diff.inkoperIdentificatie,
            koperPlaatNummer: diff.koperPlaatNummer,
            mutatieDatumTijd: new Date(),
            mutatieGebruiker: userId,
            vestigingCode: diff.vestigingCode,
          }
          promise = addEntity.mutateAsync(inkoperPlaat)
        }
        promises.push(promise)
      })
      await Promise.all(promises)
      setMustRefetchPurchaserCards(true)
      return await Promise.resolve()
    } catch (error) {
      console.log(error)
    } finally {
      setBusyUpdating(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box position={'relative'}>
      <Typography
        variant='h3'
        sx={{
          fontSize: '35px !important',
          position: 'absolute',
          marginTop: `-${ThemeConfig.spacing.sm * 6}px`,
          marginBottom: `${ThemeConfig.spacing.sm * 8}px`,
        }}
      >
        {t('common.titles.purchaserCards')}
      </Typography>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: ThemeConfig.spacing.xs * 11,
        }}
      >
        <ButtonsBlock gap={ThemeConfig.spacing.sm} marginRight={marginRight}>
          <ActionButton
            variant='contained'
            onClick={openAddDialog}
            disabled={upsertMode === 'ADD' || isPurchaserDirty()}
            sx={{
              height: ELEMENT_HEIGHT,
            }}
          >
            {t('common.add')}
          </ActionButton>
          <ActionButton
            variant='contained'
            onClick={handleSubmitCardRows}
            disabled={!areCardRowsDirty() || cardRows?.length === 0}
            sx={{
              height: ELEMENT_HEIGHT,
            }}
          >
            {t('common.save')}
          </ActionButton>
          <ActionButton
            variant='outlined'
            onClick={openClocksDialog}
            disabled={upsertMode === 'ADD'}
            sx={{
              height: ELEMENT_HEIGHT,
            }}
          >
            {t('common.clocks')}
          </ActionButton>
        </ButtonsBlock>
      </Grid>
      <Grid item xs={12}>
        <PurchaserCardsGrid />
      </Grid>

      {openAdd && (
        <AddPurchaserCardDialog
          onCancel={closeAddDialog}
          onClose={closeAddDialog}
        />
      )}
      <Suspense fallback={null}>
        <PurchaserClocksDialog
          onCancel={closeClocksDialog}
          onClose={closeClocksDialog}
          open={openClocks}
        />
      </Suspense>
    </Box>
  )
}
