import { FC } from 'react'

import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material'
import rfhTheme from '@rfh-core/theme'
import '@rfh-core/theme/dist/fonts.css'

const theme = createTheme(rfhTheme)
export const ThemeProvider: FC = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
)
