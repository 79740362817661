import { FC, ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from '@mui/material'
import { GridViewIcon, InfoIcon, LogoutIcon, UserIcon } from '@rfh-core/icons'

import { useStore } from 'zustand'

import { MenuCard } from 'src/common/components'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { ApiClientListFilter, Entity, useFetchMany } from 'src/common/hooks'
import { useAuthentication } from 'src/common/providers/AuthenticationProvider'
import { IBuyersCardView, IInkoperView } from 'src/common/services/client'
import { useUserStore } from 'src/common/stores/UserStore'
import { useBuyersCardStore } from 'src/features/buyerscards/stores/BuyersCardStore'
import {
  useBuyerStore,
  useCardRowsStore,
  usePurchaserStore,
} from 'src/features/purchasers/stores'

const HomePage: FC = (): ReactElement => {
  /*
   * Hooks
   */
  const { user, resetUserState } = useUserStore()
  const { logout } = useAuthentication()
  const { t } = useTranslation()

  const resetPurchaserState = useStore(
    usePurchaserStore,
    state => state.resetPurchaserState
  )
  const resetCardRowsState = useStore(
    useCardRowsStore,
    state => state.resetCardRowsState
  )

  const resetBuyerState = useStore(
    useBuyerStore,
    state => state.resetBuyerState
  )

  const resetBuyersCardState = useStore(
    useBuyersCardStore,
    state => state.resetBuyersCardState
  )

  const queryFilter: ApiClientListFilter = {
    top: 1,
    skip: 0,
    count: true,
  }

  const { data: buyerData } = useFetchMany<IBuyersCardView>(
    queryFilter,
    Entity.BuyersCard
  )

  const { data: purchaserData } = useFetchMany<IInkoperView>(
    queryFilter,
    Entity.IInkoperView
  )

  /*
   * Methods
   */
  const handleLogout = () => {
    resetUserState()
    logout()
  }

  useEffect(() => {
    resetPurchaserState()
    resetCardRowsState()
    resetBuyerState()
    resetBuyersCardState()
  }, [
    resetBuyerState,
    resetBuyersCardState,
    resetCardRowsState,
    resetPurchaserState,
  ])

  /*
   * Render
   */
  return (
    <Container
      maxWidth='xl'
      sx={{
        padding: ThemeConfig.spacing.sm,
        minHeight: 'calc(100vh - 64px - 8px)',
      }}
    >
      <Card className='CardSecondary' sx={{ height: '100px !important' }}>
        <CardContent>
          <Box>
            <Typography className='book' variant='h6' data-testid='welcome'>
              {t('homePage.welcome')}
            </Typography>
            <Typography variant='h4' data-testid='welcomeName'>
              {user.name && ` ${user.name.split(' ')[0]}`}
            </Typography>
          </Box>
        </CardContent>
        <CardMedia
          component='img'
          image={
            new Date().getDate() % 2 === 0
              ? '/assets/images/flower.jpg'
              : '/assets/images/leaves.jpg'
          }
        />
      </Card>
      <Grid container spacing={3} mt={4}>
        <Grid item xs={12}>
          <Typography variant='h2'>{t('homePage.actions')}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} sx={{ paddingBottom: '0 !important' }}>
          <MenuCard
            icon={
              <GridViewIcon
                sx={{
                  fontSize: '0.9em !important',
                  height: '20px',
                  width: '20px',
                }}
              />
            }
            title={
              user.isAccountManagement || user.isApplicationManager
                ? t('common.titles.buyersCards')
                : t('common.titles.buyersCardsReadOnly')
            }
            subheader={
              user.isAccountManagement || user.isApplicationManager
                ? t('common.titleDescriptions.buyersCards')
                : t('common.titleDescriptions.buyersCardsReadOnly')
            }
            to='/dashboard/buyerscards'
            bodyText={t('homePage.buyersCards', {
              count: buyerData?.count ?? 0,
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} sx={{ paddingBottom: '0 !important' }}>
          <MenuCard
            icon={
              <GridViewIcon
                sx={{
                  fontSize: '0.9em !important',
                  height: '20px',
                  width: '20px',
                }}
              />
            }
            title={
              user.isContractBeheerder || user.isApplicationManager
                ? t('common.titles.purchasers')
                : t('common.titles.purchasersReadOnly')
            }
            subheader={
              user.isContractBeheerder || user.isApplicationManager
                ? t('common.titleDescriptions.purchasers')
                : t('common.titleDescriptions.purchasersReadOnly')
            }
            to='/dashboard/purchasers'
            bodyText={t('homePage.purchasers', {
              count: purchaserData?.count ?? 0,
            })}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={4}>
        <Grid item xs={12}>
          <Typography variant='h2'>{t('homePage.manage')}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} sx={{ paddingBottom: '0 !important' }}>
          <MenuCard
            icon={
              <UserIcon
                sx={{
                  fontSize: '0.9em !important',
                  height: '20px',
                  width: '20px',
                }}
              />
            }
            title={t('common.titles.profilePage')}
            subheader={t('common.titleDescriptions.profilePage')}
            to='/dashboard/profile'
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} sx={{ paddingBottom: '0 !important' }}>
          <MenuCard
            icon={
              <InfoIcon
                sx={{
                  fontSize: '0.9em !important',
                  height: '20px',
                  width: '20px',
                }}
              />
            }
            title={t('common.titles.buyersCardsHistory')}
            subheader={t('common.titleDescriptions.buyersCardsHistory')}
            to='/dashboard/buyerscards-history'
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} sx={{ paddingBottom: '0 !important' }}>
          <MenuCard
            icon={
              <LogoutIcon
                sx={{
                  fontSize: '0.9em !important',
                  height: '20px',
                  width: '20px',
                }}
              />
            }
            title={t('common.titles.logout')}
            subheader={t('common.titleDescriptions.logout')}
            onClick={handleLogout}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default HomePage
