import {
  ChangeEvent,
  FC,
  FocusEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react'

import { FormLabel, Grid, TextField } from '@mui/material'

import { ELEMENT_HEIGHT, noOp } from 'src/common'
import { useDebouncedInput } from 'src/common/hooks'

type FormInputProps = {
  label?: string
  name?: string
  value?: string | number
  defaultValue?: string | number
  disabled?: boolean
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  sx?: Record<string, unknown>
  children?: ReactNode
}

export const FormInput: FC<FormInputProps> = ({
  label = '',
  name = '',
  defaultValue = '',
  value = '',
  disabled = false,
  onBlur = noOp,
  onChange = noOp,
  sx,
  children = null,
}) => {
  const { debounced } = useDebouncedInput()
  const [inputValue, setInputValue] = useState<string>(
    String(defaultValue || '')
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    debounced(onChange)(event)
  }

  useEffect(() => {
    setInputValue(String(value || ''))
  }, [value])

  return (
    <Grid container flexDirection={'row'} alignItems={'center'} sx={sx}>
      <Grid item xs={12} sm={5}>
        <FormLabel
          htmlFor={`id-${name}`}
          sx={{
            fontSize: '1em',
            pr: 0.5,
            maxHeight: ELEMENT_HEIGHT,
          }}
        >
          {label}
        </FormLabel>
      </Grid>
      <Grid item xs={12} sm={7} sx={{ maxHeight: ELEMENT_HEIGHT }}>
        {children ?? (
          <TextField
            id={`id-${name}`}
            name={name}
            value={inputValue}
            disabled={disabled}
            fullWidth
            onBlur={onBlur}
            onChange={handleChange}
            sx={{ fontSize: '1em' }}
          />
        )}
      </Grid>
    </Grid>
  )
}
