import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  selectClasses,
} from '@mui/material'
import theme from '@rfh-core/theme'

import { ThemeConfig } from '../config'
import { ISearchFormInput } from '../interfaces'
import { Vestiging } from '../services/client'

interface Props {
  handleChange: (name: keyof ISearchFormInput, value: string) => void
  changeValid?: (value: boolean) => void
  labelVisible?: boolean
  required?: boolean
  inputValue: number | string
  sx?: SxProps
}

const AvailableLocations: FC<Props> = (props: Props): ReactElement => {
  const { handleChange, changeValid, labelVisible, required, inputValue, sx } =
    props

  const { t } = useTranslation()

  const getLocations = () => {
    const locationData = Object.keys(Vestiging)
      .filter(item => !Number(item))
      .map(name => (
        <MenuItem
          sx={{ width: '100%' }}
          key={name}
          value={Vestiging[name as keyof typeof Vestiging]}
        >
          {name}
        </MenuItem>
      ))

    locationData.unshift(
      <MenuItem sx={{ width: '100%' }} key='' value=''>
        {t('purchaser.all')}
      </MenuItem>
    )
    return locationData
  }

  const onClickSelectItemOrClear = (event: SelectChangeEvent<string>) => {
    const { value } = event.target
    if (!value || value === '') {
      handleChange('vestiging', undefined)
      if (changeValid) {
        changeValid(false)
      }
    } else {
      handleChange('vestiging', `${value}`)
      if (changeValid) {
        changeValid(true)
      }
    }
  }

  return (
    <FormControl
      fullWidth
      sx={{
        margin: 0,
      }}
    >
      {labelVisible && (
        <FormLabel
          sx={{
            fontFamily: 'halyard-display',
            fontWeight: 'bold',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            color: theme.rfhColors.black,
          }}
          htmlFor='vestiging'
        >
          {t('buyersCards.auctionLocation')}
        </FormLabel>
      )}

      <Select
        required={required}
        data-testid='location'
        value={inputValue ? `${inputValue}` : ''}
        onChange={onClickSelectItemOrClear}
        label={t('buyersCards.auctionLocation')}
        name='vestiging'
        id='vestiging'
        sx={{
          marginTop: ThemeConfig.spacing.sm,
          bgcolor: theme.rfhColors.white,
          [`.${selectClasses.icon}`]: {
            pointerEvents: 'none',
          },
          ...sx,
        }}
      >
        {getLocations()}
      </Select>
    </FormControl>
  )
}

export default AvailableLocations
