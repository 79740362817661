import { buildVersion } from 'src/common/config/buildVersion.json'

import * as packageJson from '../../../package.json'

type UseDevToolsReturn = {
  forceMajeur: boolean
  version: string
}

const useDevTools = (): UseDevToolsReturn => {
  const forceMajeur = window.sessionStorage.getItem('dev-tools') === 'false'
  const version = getBuildVersion()
  return { forceMajeur, version }
}

export default useDevTools

function getBuildVersion(): string {
  const devVersion = packageJson.version
  const prdVersion = packageJson.version + (buildVersion || '')
  const version =
    process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
      ? devVersion
      : prdVersion
  return version
}
