import { FC } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'

export const PurchaserDetailsSkeleton: FC = () => {
  const dummyArray = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
  ]

  return (
    <Grid
      container
      spacing={2}
      sx={{
        width: '80%',
        marginTop: 2,
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          padding: '0 !important',
          marginLeft: 2,
        }}
      >
        <Grid item mb={2}>
          <Skeleton variant={'text'} sx={{ fontSize: '3em', width: '60%' }} />
        </Grid>
        <Grid container flexDirection='row' width={'100%'}>
          <Grid item xs={6} paddingRight={2}>
            <Stack spacing={1}>
              {dummyArray.map(el => (
                <Box display={'flex'} gap={2} key={el.id}>
                  <Skeleton variant='rectangular' width={'35%'} height={40} />
                  <Skeleton variant='rectangular' width={'65%'} height={40} />
                </Box>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={6} paddingLeft={3}>
            <Stack spacing={1}>
              <Box display={'flex'} gap={2}>
                <Skeleton variant='rectangular' width={'33.7%'} height={40} />
                <Skeleton variant='rectangular' width={'10%'} height={40} />
              </Box>
              {dummyArray.slice(2).map(el => (
                <Box display={'flex'} gap={2} key={el.id}>
                  <Skeleton variant='rectangular' width={'35%'} height={40} />
                  <Skeleton variant='rectangular' width={'65%'} height={40} />
                </Box>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
