import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  Button,
  FormControl,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material'

import AvailableLocations from 'src/common/components/AvailableLocations'
import CustomerInput from 'src/common/components/CustomerInput'
import LabelBooleanDropdown from 'src/common/components/LabelBooleanDropdown/LabelBooleanDropdown'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { useFilter } from 'src/common/hooks'
import { noOp } from 'src/common/index'
import {
  IBuyersCardsFilter,
  ISearchFormInput,
} from 'src/common/interfaces/interfaces'
import { useUserStore } from 'src/common/stores/UserStore'
import { constructFilterString } from 'src/common/utils/FilterFunctions'

import { generateFilterString } from '../lib'

const BuyersCardsFilter: FC<IBuyersCardsFilter> = ({
  changeFilter,
  resetFilter,
}) => {
  /*
  State
  */
  const keyBuyersCardsFilterValue = 'BuyersCardsFilter-values'
  const defaultFilter: ISearchFormInput = {
    customerRfhRelationId: undefined,
    customrRfhRelationName: undefined,
    buyersCardId: undefined,
    vestiging: '',
    isVervallen: undefined,
    blockedindication: undefined,
  }

  /*
  Hooks
  */
  const { t } = useTranslation()
  const { user } = useUserStore()

  const setFilterString = (newValues: ISearchFormInput) => {
    const newFilter = constructFilterString(newValues, generateFilterString)
    changeFilter(newFilter)
  }

  const { filter, clear, handleChange, handleRequest } =
    useFilter<ISearchFormInput>({
      defaultFilter,
      resetFilter,
      setFilterString,
      cacheKey: keyBuyersCardsFilterValue,
    })

  const handleInputChange = (evt: any): void => {
    const { name, value } = evt.target
    handleChange(name as keyof ISearchFormInput, value)
  }

  const handleInputChangeNumber = (evt: any): void => {
    const { name, value } = evt.target
    const parsedValue = value ? parseInt(value, 10) : undefined
    handleChange(name as keyof ISearchFormInput, parsedValue)
  }
  const handleInputChangeSelect = ({ target }: SelectChangeEvent<string>) => {
    const { name, value } = target
    const isDefined = ['true', 'false'].includes(value)
    const isTrue = isDefined && value === 'true'
    if (isDefined) {
      handleChange(name as keyof ISearchFormInput, isTrue)
    } else {
      handleChange(name as keyof ISearchFormInput, undefined)
    }
  }

  useEffect(() => {
    if (filter) {
      setFilterString(filter)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /*
  Render
  */
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'end'}
      marginBottom={ThemeConfig.spacing.sm}
    >
      <Stack
        direction={'row'}
        justifyContent={'justify-start'}
        gap={ThemeConfig.spacing.s}
        alignItems={'end'}
      >
        <CustomerInput
          showOnlyAvailableBuyers={false}
          changeCustomerNumber={value =>
            handleChange('customerRfhRelationId', value)
          }
          changeValid={noOp}
          value={
            filter.customerRfhRelationId
              ? filter.customerRfhRelationId
              : undefined
          }
        />

        <FormControl fullWidth>
          <TextField
            id='customrRfhRelationName'
            label={t('buyersCards.customerName')}
            name='customrRfhRelationName'
            placeholder={t('buyersCards.customerName')}
            value={
              filter.customrRfhRelationName ? filter.customrRfhRelationName : ''
            }
            onChange={handleInputChange}
            fullWidth
          />
        </FormControl>

        <FormControl fullWidth>
          <TextField
            id='buyersCardId'
            label={t('buyersCards.plateNumber')}
            name='buyersCardId'
            placeholder={t('buyersCards.plateNumber')}
            value={filter.buyersCardId ? filter.buyersCardId : ''}
            onChange={handleInputChangeNumber}
            fullWidth
          />
        </FormControl>

        <AvailableLocations
          handleChange={handleChange}
          inputValue={filter.vestiging}
          labelVisible
        />

        <LabelBooleanDropdown
          onChange={handleInputChangeSelect}
          label={t('buyersCards.expired')}
          inputValue={filter.isVervallen}
          fieldname={'isVervallen'}
          labelVisible
        />

        <LabelBooleanDropdown
          label={t('buyersCards.blocked')}
          onChange={handleInputChangeSelect}
          inputValue={filter.blockedindication}
          fieldname={'blockedindication'}
          labelVisible
        />
      </Stack>

      <Stack
        direction={'row'}
        justifyContent={'justify-end'}
        alignItems={'end'}
        gap={ThemeConfig.spacing.s}
      >
        <Button
          variant='outlined'
          onClick={clear}
          sx={{
            height: ThemeConfig.spacing.xl * 4.9,
            mr: ThemeConfig.spacing.sm,
          }}
        >
          {t('common.clear')}
        </Button>
        {user.isAccountManagement || user.isApplicationManager ? (
          <Button
            variant='contained'
            component={Link}
            to='/dashboard/buyerscards/add'
            sx={{
              height: ThemeConfig.spacing.xl * 4.9,
              mr: ThemeConfig.spacing.sm,
            }}
          >
            {t('common.add')}
          </Button>
        ) : null}

        <Button
          variant='contained'
          type='submit'
          onClick={handleRequest}
          sx={{
            height: ThemeConfig.spacing.xl * 4.9,
          }}
        >
          {t('common.search')}
        </Button>
      </Stack>
    </Stack>
  )
}

export default BuyersCardsFilter
