import { addDays } from 'date-fns'
import { t } from 'i18next'

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium'
import { AlertOIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import BooleanTag from 'src/common/components/BooleanTag'
import { IBuyersCardView, formatDate } from 'src/common/index'

export const getBuyerCardColumns = (sortingAllowed: boolean): GridColDef[] => [
  {
    field: 'customerRfhRelationId',
    headerName: t('buyersCards.customerNumber'),
    sortable: sortingAllowed,
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    hideable: false,
  },
  {
    field: 'customrRfhRelationName',
    headerName: t('buyersCards.customerName'),
    flex: 3,
    sortable: sortingAllowed,
  },
  {
    field: 'buyersCardId',
    headerName: t('buyersCards.plateNumber'),
    align: 'right',
    headerAlign: 'right',
    flex: 1,
    sortable: sortingAllowed,
  },
  {
    field: 'aalsmeer',
    headerName: 'A',
    type: 'boolean',
    align: 'center',
    headerAlign: 'center',
    flex: 0.6,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <BooleanTag value={params.row.aalsmeer} />
    ),
  },
  {
    field: 'naaldwijk',
    headerName: 'N',
    type: 'boolean',
    align: 'center',
    headerAlign: 'center',
    flex: 0.6,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <BooleanTag value={params.row.naaldwijk} />
    ),
  },
  {
    field: 'rijnsburg',
    headerName: 'R',
    type: 'boolean',
    align: 'center',
    headerAlign: 'center',
    flex: 0.6,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <BooleanTag value={params.row.rijnsburg} />
    ),
  },
  {
    field: 'eelde',
    headerName: 'E',
    type: 'boolean',
    align: 'center',
    headerAlign: 'center',
    flex: 0.6,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <BooleanTag value={params.row.eelde} />
    ),
  },
  {
    field: 'startDate',
    headerName: t('buyersCards.startDate'),
    type: 'date',
    sortable: sortingAllowed,
    flex: 1.5,
    valueFormatter: row => formatDate(row, t('common.dateFormatShort')),
  },
  {
    field: 'endDate',
    headerName: t('buyersCards.endDate'),
    type: 'date',
    sortable: sortingAllowed,
    flex: 1.5,
    valueFormatter: row => formatDate(row, t('common.dateFormatShort')),
  },
  {
    field: 'releasedDateTime',
    headerName: t('buyersCards.releaseDate'),
    type: 'dateTime',
    sortable: sortingAllowed,
    flex: 1.5,
    valueFormatter: row => formatDate(row, t('common.dateFormatShort')),
  },
  {
    field: 'blockedDateTime',
    headerName: t('buyersCards.blockDate'),
    type: 'dateTime',
    sortable: sortingAllowed,
    flex: 1.5,
    valueFormatter: row => formatDate(row, t('common.dateFormatShort')),
  },
  {
    field: 'isVervallen',
    type: 'boolean',
    headerName: t('buyersCards.expired'),
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    sortable: sortingAllowed,
    renderCell: (params: GridRenderCellParams) =>
      params.row.isVervallen ? (
        <AlertOIcon
          sx={{
            color: `${theme.rfhColors.alertRed}`,
          }}
        />
      ) : (
        '-'
      ),
  },
  {
    field: 'blockedindication',
    type: 'boolean',
    headerName: t('buyersCards.blocked'),
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    sortable: sortingAllowed,
    valueFormatter: ({ value }) => value, // Needed for csv exports to supply the boolean as `Yes` and `No`, it uses the value from the valueGetter
    valueGetter: ({ value }) => (value ? t('common.yes') : t('common.no')), // Needed for excel exports to supply the boolean as `Yes` and `No`
    renderCell: (params: GridRenderCellParams) =>
      params.row.blockedindication ? (
        <AlertOIcon
          sx={{
            color: `${theme.rfhColors.alertRed}`,
          }}
        />
      ) : (
        '-'
      ),
  },
  {
    field: 'remark',
    headerName: t('common.remark'),
    align: 'left',
    headerAlign: 'left',
    flex: 1.5,
    sortable: sortingAllowed,
  },
]

export const defaultBuyerscard: IBuyersCardView = {
  customerRfhRelationId: undefined,
  buyersCardId: undefined,
  aalsmeer: false,
  naaldwijk: false,
  rijnsburg: false,
  eelde: false,
  creationUser: undefined,
  creationDateTime: new Date(),
  mutationDateTime: new Date(),
  mutationUser: undefined,
  blockedDateTime: null,
  isVervallen: false,
  releasedDateTime: null,
  blockedindication: false,
  remark: '',
  startDate: addDays(new Date(), 1),
  endDate: new Date('2099-12-31T23:59:59'),
}
