export const constructFilterString = <T>(
  searchValues: T,
  createFilter: (key: keyof T, value: any, filterRules: string[]) => string[]
): string => {
  if (!searchValues) {
    return ''
  }
  let filterRules: string[] = []
  // Loop over each value of the data and assign the correct filter rule
  for (const [key, value] of Object.entries(searchValues)) {
    if (!key || value == null) {
      continue
    }
    filterRules = createFilter(key as keyof T, value, filterRules)
  }

  if (filterRules.length === 0) {
    return ''
  }

  return filterRules.filter(s => !!s).join(' and ')
}

export const generateDefaultFilterString = (
  key: string,
  value: string | number,
  filterRules: string[]
): string[] => {
  let filterRule = ''
  if (key) {
    if (typeof value === 'string') {
      filterRule = `contains(${key}, '${value}')`
    } else if (value !== 0) {
      filterRule = `${key} eq ${value}`
    }

    if (filterRule !== '') {
      filterRules.push(filterRule)
    }
  }
  return filterRules
}
