import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'

import { Alert, AlertTitle, IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium'
import { DeleteIcon, EditIcon, InfoIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'
import { QueryObserverResult } from '@tanstack/react-query'

import CustomDataGrid from 'src/common/components/CustomDataGrid'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { ListAndCount } from 'src/common/hooks'
import { IBuyersCardView } from 'src/common/services/client'
import { useUserStore } from 'src/common/stores/UserStore'
import { getBuyerCardColumns } from 'src/features/buyerscards/constants'
import i18n from 'src/i18n'

import BuyersCardsDialogDelete from './BuyersCardsDialogDelete'
import BuyersCardsDetails from './buyersCardsDetails/BuyersCardsDetails'

type BuyersCardsGridProps = {
  isLoading: boolean
  refresh: () => Promise<
    QueryObserverResult<ListAndCount<IBuyersCardView>, unknown>
  >
  rows: IBuyersCardView[]
  rowCount: number
}

export default function BuyersCardsGrid({
  rows,
  isLoading,
  refresh,
  rowCount,
}: Readonly<BuyersCardsGridProps>): JSX.Element {
  const { t } = useTranslation()
  const { user } = useUserStore()
  const history = useHistory()
  const { cardId }: any = useParams()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [selectedBuyersCardToBeDeleted, setSelectedBuyersCardToBeDeleted] =
    useState<IBuyersCardView>()
  const [hideAlertContainer, setHideAlertContainer] = useState(false)

  const gotoBuyerscardDetails = useCallback(
    (params: GridCellParams<any, IBuyersCardView, any>) => {
      if (params?.row?.buyersCardId === undefined) {
        return
      }

      // Navigate to the details unless the checkbox cell, delete or edit button is clicked
      if (
        params?.field !== '__check__' &&
        params?.field !== 'verwijderen' &&
        params?.field !== 'bewerken'
      ) {
        history.push(`/dashboard/buyerscard/${params.row.buyersCardId}`)
        setDialogOpen(true)
      }
    },
    [history]
  )

  const closeBuyerscardDetails = () => {
    history.push('/dashboard/buyerscards')
    setDialogOpen(false)
  }

  const openDeleteAlert = (value: IBuyersCardView) => () => {
    setSelectedBuyersCardToBeDeleted(value)
    setDeleteOpen(true)
  }

  const closeDeleteAlert = () => {
    setDeleteOpen(false)
    setSelectedBuyersCardToBeDeleted(undefined)
  }

  // Because we use server side sorting we only allow sorting when there are no more than 300.000 rows
  const maxSortedRows = 300000
  const sortingAllowed = rowCount <= maxSortedRows

  useEffect(() => {
    if (cardId) {
      gotoBuyerscardDetails({
        row: { buyersCardId: cardId },
      } as any)
    }
  }, [cardId, gotoBuyerscardDetails])

  const getColumns = useCallback((): GridColDef[] => {
    const newColumns: GridColDef[] = [...getBuyerCardColumns(sortingAllowed)]
    if (user.isAccountManagement || user.isApplicationManager) {
      newColumns.push({
        field: 'bewerken',
        headerName: t('common.edit'),
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        hideable: false,
        sortable: false,
        disableExport: true,
        renderHeader: () => (
          <EditIcon
            sx={{
              color: theme.rfhColors.white,
              width: '20px',
              height: '20px',
            }}
          />
        ),
        renderCell: (params: GridRenderCellParams) =>
          !params.row.isVervallen ? (
            <IconButton
              component={Link}
              to={`/dashboard/buyerscards/${params.row.buyersCardId}/edit`}
            >
              <EditIcon
                sx={{
                  cursor: 'pointer',
                  color: `${theme.rfhColors.grey[800]}`,
                  width: '20px',
                  height: '20px',
                }}
              />
            </IconButton>
          ) : (
            <EditIcon
              sx={{
                color: `${theme.rfhColors.grey[100]}`,
                width: '20px',
                height: '20px',
              }}
            />
          ),
        flex: 0.5,
      })
      newColumns.push({
        field: 'verwijderen',
        headerName: t('common.delete'),
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        hideable: false,
        sortable: false,
        disableExport: true,
        renderHeader: () => (
          <DeleteIcon
            sx={{
              color: theme.rfhColors.white,
              width: '20px',
              height: '20px',
            }}
          />
        ),
        renderCell: (params: GridRenderCellParams) =>
          !params.row.isVervallen ? (
            <IconButton onClick={openDeleteAlert(params.row)}>
              <DeleteIcon
                data-testid='delete'
                sx={{
                  cursor: 'pointer',
                  color: theme.rfhColors.grey[800],
                  width: '20px',
                  height: '20px',
                }}
              />
            </IconButton>
          ) : (
            <DeleteIcon
              sx={{
                color: `${theme.rfhColors.grey[100]}`,
                width: '20px',
                height: '20px',
              }}
            />
          ),
        flex: 0.5,
      })
    }

    return newColumns
  }, [sortingAllowed, t, user.isAccountManagement, user.isApplicationManager])

  return (
    <Grid container spacing={2}>
      {!sortingAllowed && hideAlertContainer ? (
        <Box
          sx={{
            marginBottom: 2,
            width: '100%',
          }}
        >
          <Alert
            color='warning'
            icon={<InfoIcon />}
            onClose={() => setHideAlertContainer(true)}
          >
            <AlertTitle>
              {t('buyersCards.sortingDisabledAlertTitle')}
            </AlertTitle>
            {t('buyersCards.sortingDisabledAlertMessage')}
          </Alert>
        </Box>
      ) : null}
      <Grid
        item
        xs={12}
        sx={{
          flexGrow: 1,
          padding: '0 !important',
          marginBottom: ThemeConfig.spacing.m,
        }}
      >
        <CustomDataGrid
          columns={getColumns()}
          getRowId={row => row.buyersCardId}
          gridName={'buyersCards'}
          gridProps={{
            export: {
              title: t('purchaser.purchaserCards'),
            },
            loading: isLoading,
            onCellClick: gotoBuyerscardDetails,
            rowCount,
          }}
          language={i18n.language}
          rows={rows}
        />
      </Grid>
      <BuyersCardsDetails
        open={dialogOpen}
        cardId={cardId}
        onClose={closeBuyerscardDetails}
        refresh={refresh}
      />
      <BuyersCardsDialogDelete
        buyersCardToBeDeleted={selectedBuyersCardToBeDeleted}
        open={deleteOpen}
        onClose={closeDeleteAlert}
        onCancel={closeDeleteAlert}
        refresh={refresh}
      />
    </Grid>
  )
}
