import {
  IBuyersCardView,
  IInkoperPlaatView,
  IInkoperView,
  Vestiging,
} from 'src/common/services/client'

import { containsLocation, getUniqueCardNumbers, hasLocation } from '../lib'
import { InkoperPlaatRow } from '../types'

export const useRows = (
  inkoper: IInkoperView,
  platen: IInkoperPlaatView[],
  koperplaten: IBuyersCardView[]
): InkoperPlaatRow[] => {
  const plateNumbers = getUniqueCardNumbers(platen || [])

  return plateNumbers.map(pn => {
    const subSet = platen.filter(p => p.koperPlaatNummer === pn)
    const buyerscards = koperplaten.filter(k => k.buyersCardId === pn)
    return {
      inkoperIdentificatie: inkoper?.inkoperIdentificatie ?? '',
      koperPlaatNummer: pn,
      koperNummer: buyerscards[0]?.customerRfhRelationId ?? undefined,
      koperNaam: buyerscards[0]?.customrRfhRelationName ?? '',
      aalsmeer: containsLocation(subSet, Vestiging.Aalsmeer),
      aalsmeerDisabled: !hasLocation(buyerscards, Vestiging.Aalsmeer),
      naaldwijk: containsLocation(subSet, Vestiging.Naaldwijk),
      naaldwijkDisabled: !hasLocation(buyerscards, Vestiging.Naaldwijk),
      rijnsburg: containsLocation(subSet, Vestiging.Rijnsburg),
      rijnsburgDisabled: !hasLocation(buyerscards, Vestiging.Rijnsburg),
      eelde: containsLocation(subSet, Vestiging.Eelde),
      eeldeDisabled: !hasLocation(buyerscards, Vestiging.Eelde),
    } as InkoperPlaatRow
  })
}
