import { ComponentProps, FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button as MuiButton, SxProps } from '@mui/material'

import { ThemeConfig } from 'src/common/config'
import { ELEMENT_HEIGHT } from 'src/common/constants'

type ActionButtonProps = ComponentProps<typeof MuiButton> & {
  buttonSx?: SxProps
  isLoading?: boolean
  height?: number
  marginRight?: number
  width?: number
}

/**
 * Renders a custom action button using the RfhButton component from '@rfh/ui/components/RfhButton'
 *
 * @component
 * @param {Object} props - The component props bag
 * @param {Function} props.onClick - The function to be called when the button is clicked
 * @param {number} [props.height=76.8] - The height of the button in pixels
 * @param {number} [props.width=192] - The width of the button in pixels
 * @param {number} [props.marginRight=0.65] - The margin right of the button in relative units
 * @param {'small' | 'medium' | 'large'} [props.size='small'] - The size of the button
 * @param {'button' | 'reset' | 'submit'} [props.type='button'] - The type of the button
 * @param {'block--contained' | 'block--outlined' | 'text--primary' | 'text--secondary'} [props.variant='block--contained'] - The variant of the button
 * @param {Object} [props.buttonSx] - The sx prop passed to the Button component
 * @param {ReactNode} [props.children] - The children of the button, defaults to t('common.save')
 * @return {JSX.Element}
 */
const ActionButton: FC<ActionButtonProps> = props => {
  const {
    buttonSx,
    disabled = false,
    height = ELEMENT_HEIGHT,
    marginRight = 0,
    onClick,
    type = 'button',
    variant = 'contained',
    width = ThemeConfig.spacing.xl * 16,
    isLoading,
    children,
  } = props
  const { t } = useTranslation()

  return (
    <MuiButton
      disabled={disabled}
      onClick={onClick}
      type={type}
      variant={variant}
      isLoading={isLoading}
      sx={{
        pointerEvents: 'auto',
        height,
        width,
        marginRight,
        ...buttonSx,
      }}
      {...props}
    >
      {children ?? t('common.save')}
    </MuiButton>
  )
}

export default ActionButton
