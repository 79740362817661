import { ChangeEvent, FocusEvent } from 'react'

import { FormControl, FormLabel, Grid, SxProps } from '@mui/material'
import CheckBox from '@mui/material/Checkbox'
import theme from '@rfh-core/theme'

type LabelCheckboxProps = {
  label: string
  fieldName: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLButtonElement>) => void
  value: boolean
  disabled?: boolean
  flowDirection: 'row' | 'column' | 'column-reverse' | 'row-reverse'
  sx?: SxProps
}

export default function LabelCheckbox({
  label,
  fieldName,
  flowDirection,
  onChange,
  onBlur,
  value = false,
  disabled,
  sx,
}: Readonly<LabelCheckboxProps>): JSX.Element {
  return (
    <Grid
      container
      item
      direction={flowDirection}
      justifyContent={'flex-start'}
      alignItems={'center'}
    >
      <Grid item>
        <FormControl fullWidth>
          <FormLabel
            htmlFor={`id-${fieldName}`}
            sx={{
              color: theme.rfhColors.black,
              whiteSpace: 'nowrap',
              minWidth: 170,
              textAlign: 'center',
              ...sx,
            }}
          >
            {label}
          </FormLabel>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <CheckBox
            id={`id-${fieldName}`}
            name={fieldName}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            checked={value}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}
