import { v4 as getRandomID } from 'uuid'

import { useTranslation } from 'react-i18next'

import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  selectClasses,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import theme from '@rfh-core/theme'

import { ThemeConfig } from 'src/common/config'
import { ELEMENT_HEIGHT } from 'src/common/constants'
import type { Item } from 'src/common/types'

type Props = {
  onChange?: (event: SelectChangeEvent<string>) => void
  label?: string
  labelVisible?: boolean
  disabled?: boolean
  fieldName?: string
  values?: Item<string>[]
  value?: string
  sx?: SxProps
}

export default function LabelDropdownText({
  disabled,
  fieldName,
  label,
  labelVisible = false,
  onChange,
  values,
  value,
  sx,
}: Readonly<Props>): JSX.Element {
  const { t } = useTranslation()
  const getOptions = () => {
    if (values.length === 0) {
      return (
        <MenuItem sx={{ minWidth: 160 }} key={getRandomID()} disabled value=''>
          {t('common.noResults')}
        </MenuItem>
      )
    }

    return values.map(item => (
      <MenuItem sx={{ minWidth: 160 }} key={getRandomID()} value={item.code}>
        {item.name}
      </MenuItem>
    ))
  }
  return (
    <Grid item>
      <FormControl fullWidth>
        {labelVisible && (
          <FormLabel
            sx={{
              fontFamily: 'halyard-display',
              fontWeight: 'bold',
              fontSize: '1rem',
              lineHeight: '1.5rem',
              color: theme.rfhColors.black,
            }}
            htmlFor={`id-${fieldName}`}
          >
            {label}
          </FormLabel>
        )}
        <Select
          value={value ?? ''}
          defaultValue=''
          onChange={onChange}
          disabled={disabled}
          id={`id-${fieldName}`}
          aria-label={label}
          name={fieldName}
          autoWidth
          sx={{
            minWidth: 160,
            width: '100%',
            bgcolor: theme.rfhColors.white,
            mt: ThemeConfig.spacing.sm,
            [`& .${selectClasses.select}`]: {
              pointerEvents: 'auto',
            },
            maxHeight: ELEMENT_HEIGHT,
            ...sx,
          }}
        >
          {getOptions()}
        </Select>
      </FormControl>
    </Grid>
  )
}
