import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { InputAdornment, TextField } from '@mui/material'

import { useDebouncedInput } from 'src/common/hooks'

type PasswordProps = {
  [rest: string]: any
  name: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  value: string
}

export const PasswordField: FC<PasswordProps> = ({
  name,
  onChange,
  value,
  ...rest
}) => {
  const { debounced } = useDebouncedInput()
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState<string>('')

  useEffect(() => {
    setPassword(value)
  }, [value])
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
    debounced(onChange)(event)
  }
  const handleClickShowPassword = useCallback(
    () => setShowPassword(show => !show),
    []
  )

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      name={name}
      value={password}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end' onClick={handleClickShowPassword}>
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  )
}
