import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Container, Grid, Typography } from '@mui/material'

import {
  Entity,
  allGridsDefaults,
  getCurrentPaginationModel,
  getCurrentSortModel,
  useFetchMany,
  useGridStore,
  useUserStore,
} from 'src/common'
import { Divider } from 'src/common/components/Divider'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { IBuyersCardView } from 'src/common/services/client'
import { getOrderBy, getSkip, getTop } from 'src/common/utils/ODataHelpers'

import BuyersCardsFilter from '../components/BuyersCardsFilter'
import BuyersCardsGrid from '../components/BuyersCardsGrid'

export default function BuyersCards(): JSX.Element {
  const { CACHE_KEY } = allGridsDefaults.buyersCards
  const ENABLED = true as const
  const { t } = useTranslation()
  const { user } = useUserStore()
  const { setGridChanged, setShouldPaginationBeReset } = useGridStore()
  const [filter, setFilter] = useState<string>(undefined)

  const {
    data: buyerscards,
    isLoading,
    refetch,
  } = useFetchMany<IBuyersCardView>(
    {
      top: getTop(getCurrentPaginationModel(CACHE_KEY)),
      skip: getSkip(getCurrentPaginationModel(CACHE_KEY)),
      filter: filter || undefined,
      count: true,
      orderby: getOrderBy(getCurrentSortModel(CACHE_KEY)),
    },
    Entity.BuyersCardView,
    ENABLED
  )

  const updateFilter = (value: string) => {
    setFilter(value)
    setShouldPaginationBeReset(true)
    setGridChanged('buyersCards')
  }

  const resetFilter = () => {
    setFilter(undefined)
    setShouldPaginationBeReset(true)
    setGridChanged('buyersCards')
  }

  return (
    <Container
      maxWidth='xl'
      sx={{
        maxWidth: '1920px !important',
        minHeight: 'calc(100vh - 64px - 8px)',
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          width: '100%',
          margin: '0',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            padding: '0 !important',
          }}
        >
          <Typography
            variant={'h1'}
            sx={{
              fontSize: '4.5rem',
              my: `${ThemeConfig.spacing.sm * 8}px`,
            }}
          >
            {user.isAccountManagement || user.isApplicationManager
              ? t('common.titles.buyersCards')
              : t('common.titles.buyersCardsReadOnly')}
          </Typography>
        </Grid>

        <Grid
          item
          sm={12}
          mt={ThemeConfig.spacing.m}
          mb={ThemeConfig.spacing.l}
        >
          <BuyersCardsFilter
            changeFilter={updateFilter}
            resetFilter={resetFilter}
          />
        </Grid>
        <Divider {...{ marginTop: 2, width: '100%' }} />
        <Grid item sm={12}>
          <BuyersCardsGrid
            rows={buyerscards?.records ?? []}
            isLoading={isLoading}
            refresh={refetch}
            rowCount={
              buyerscards?.count ? Number(buyerscards?.count.toString()) : 0
            }
          />
        </Grid>
      </Grid>
    </Container>
  )
}
