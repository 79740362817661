import { FC } from 'react'

import {
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-premium'

type Props = {
  title?: string
}

const GridToolbar: FC<Props> = ({ title }) => (
  <GridToolbarContainer>
    <GridToolbarExport
      csvOptions={{ fileName: title ?? document.title }}
      excelOptions={{ fileName: title ?? document.title }}
      printOptions={{ disableToolbarButton: true }}
    />
  </GridToolbarContainer>
)

export default GridToolbar
