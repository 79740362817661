import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { getFuncByEntity } from 'src/common/lib'
import { useApiClient } from 'src/common/providers'
import SnackbarUtils from 'src/common/utils/SnackbarUtils'

import { ApiClientAddFuncType, EntityType, FuncSort } from './own/lib.resources'

export const useAddEntity = <T>(
  entity: EntityType
): UseMutationResult<T, unknown, T, unknown> => {
  const { apiClient } = useApiClient()
  const func = getFuncByEntity(FuncSort.add, entity) as ApiClientAddFuncType
  const queryClient = useQueryClient()

  const result = useMutation({
    mutationKey: [entity, FuncSort.add],
    mutationFn: (body: any) => apiClient[func](body) as Promise<T>,
    onSettled: () => {
      queryClient.invalidateQueries()
    },
  })

  if (result.isError) {
    SnackbarUtils.error(`${result.error}`)
  }

  return result
}
