/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import jwt from 'jwt-decode'

import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useOktaAuth } from '@okta/okta-react'

import { Config } from 'src/common/config/Config'

import { useUserStore } from '../stores/UserStore'

export interface IUser {
  name: string
  preferredUsername: string
  sub: string
  email: string
  accessToken: string
  isAuthenticated: boolean
  isReadOnly: boolean
  isApplicationManager: boolean
  isAccountManagement: boolean
  isContractBeheerder: boolean
}

export const initialUser: IUser = {
  name: '',
  preferredUsername: '',
  sub: '',
  email: '',
  accessToken: '',
  isAuthenticated: false,
  isReadOnly: false,
  isApplicationManager: false,
  isAccountManagement: false,
  isContractBeheerder: false,
}

export const UserContext = createContext<IUser>(initialUser)
UserContext.displayName = 'UserContext'

/**
 * Use this hook to get all the needed info about the user
 * @example const user = useUser()
 */
export const useUser = () => {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error('useUser hook was used outside of its context provider')
  }

  return context
}

/**
 * This provider is used to give all the child's access to the useUser hook that can be used
 * to access all information of an user. By using this pattern we are able to make the hook a singleton
 * what greatly improves performance and maintainability.
 */
export const UserProvider: FC = ({ children }) => {
  const { authState } = useOktaAuth()
  const { setMayOverruleRoles, setUser, resetUserState } = useUserStore()
  const [localUser, setLocalUser] = useState(initialUser)

  const hasRole = (token: string, propName: string): boolean => {
    const jsonToken = jwt<any>(token)
    const propNameWithEnv = `${propName}-${Config.env}`
    const propValue = jsonToken[propNameWithEnv]
      ? jsonToken[propNameWithEnv]
      : ''

    return propValue === 'true'
  }

  const update = useCallback((): void => {
    const accessToken = authState.accessToken.accessToken
    const idToken = authState.idToken.claims
    const isReadOnly = hasRole(
      accessToken,
      'rru-veilcontractbeheer-isalleenlezen'
    )
    const isApplicationManager = hasRole(
      accessToken,
      'rru-veilcontractbeheer-isapplicatiebeheerder'
    )
    const isAccountManagement = hasRole(
      accessToken,
      'rru-veilcontractbeheer-isaccountmanagement'
    )
    const isContractBeheerder = hasRole(
      accessToken,
      'rru-veilcontractbeheer-iscontractbeheerder'
    )
    const sub = jwt<any>(accessToken).sub

    const updatedUser: IUser = {
      name: idToken.name,
      preferredUsername: idToken.preferred_username,
      sub,
      email: idToken.email,
      accessToken,
      isAuthenticated: true,
      isReadOnly,
      isApplicationManager,
      isAccountManagement,
      isContractBeheerder,
    }

    setLocalUser(updatedUser)
    setUser(updatedUser)
    if (updatedUser.isApplicationManager) setMayOverruleRoles()
  }, [
    authState?.accessToken?.accessToken,
    authState?.idToken?.claims,
    setMayOverruleRoles,
    setUser,
  ])

  useEffect(() => {
    if (authState?.isAuthenticated) {
      update()
    } else {
      setLocalUser(initialUser)
      resetUserState()
    }
  }, [
    authState?.accessToken?.accessToken,
    authState?.isAuthenticated,
    resetUserState,
    update,
  ])

  return (
    <UserContext.Provider value={localUser}>{children}</UserContext.Provider>
  )
}
