/* eslint-disable @typescript-eslint/no-unused-vars */
import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import { IUser, initialUser } from '../providers/UserProvider'

export type StoreState = {
  user: IUser
  mayOverruleRoles: boolean
}

export type Actions = {
  resetUserState: () => void
  setUser: (newUser: IUser) => void
  setRole: (key: RoleOptions, input: boolean) => void
  setMayOverruleRoles: () => void
}

const calcNewRole = (
  key: RoleOptions,
  input: boolean,
  valApplication: boolean,
  valAccout: boolean,
  valContract: boolean
) => {
  if (key !== 'isAccountManagement' && key !== 'isContractBeheerder') {
    return valApplication
  }
  if (input) return false // any other role disables isApplicationManager
  // input is false
  if (key === 'isAccountManagement') return !valContract
  if (key === 'isContractBeheerder') return !valAccout
}

const storeName = 'UserStore'

const initialStoreState: StoreState = {
  user: initialUser,
  mayOverruleRoles: false,
}

export type RoleOptions = {
  [k in keyof IUser]: IUser[k] extends boolean ? k : never
}[keyof IUser]

export const useUserStore = create<StoreState & Actions>()(
  devtools(
    persist(
      (set, get: () => any) => ({
        ...initialStoreState,
        resetUserState: () =>
          set(() => initialStoreState, false, 'resetUserState'),
        setUser: (newUser: IUser) =>
          set(
            (state: StoreState) =>
              state.mayOverruleRoles
                ? {
                    user: {
                      ...state.user,
                      isApplicationManager:
                        !state.user.isAccountManagement &&
                        !state.user.isContractBeheerder,
                      isAccountManagement: state.user.isAccountManagement,
                      isContractBeheerder: state.user.isContractBeheerder,
                    },
                  }
                : { user: { ...newUser } },
            false,
            'setUser'
          ),
        setRole: (key: RoleOptions, input: boolean) =>
          set(
            (state: StoreState) =>
              state.mayOverruleRoles
                ? {
                    user: {
                      ...state.user,
                      [key]: input,
                      isApplicationManager: calcNewRole(
                        key,
                        input,
                        state.user.isApplicationManager,
                        state.user.isAccountManagement,
                        state.user.isContractBeheerder
                      ),
                    },
                  }
                : {
                    user: {
                      ...state.user,
                      [key]: input,
                    },
                  },
            false,
            'setRole'
          ),
        setMayOverruleRoles: () =>
          set({ mayOverruleRoles: true }, false, 'setMayOverruleRoles'),
      }),
      {
        name: 'user-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)
