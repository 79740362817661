import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  inputBaseClasses,
} from '@mui/material'

import { ELEMENT_HEIGHT } from 'src/common'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { Entity, useAddEntity, useFetchOne } from 'src/common/hooks'
import {
  IBuyersCard,
  IInkoperPlaat,
  Vestiging,
} from 'src/common/services/client'
import SnackbarUtils from 'src/common/utils/SnackbarUtils'

import { usePurchaserStore } from '../stores'

type AddPurchaserCardDialogProps = {
  onClose: () => void
  onCancel: () => void
}

export const AddPurchaserCardDialog: FC<AddPurchaserCardDialogProps> = ({
  onClose: close,
  onCancel: cancel,
}) => {
  const { t } = useTranslation()
  const addEntity = useAddEntity<IInkoperPlaat>(Entity.IInkoperPlaat)
  const {
    userId,
    purchaser,
    setMustRefetchPurchaser,
    setMustRefetchPurchaserCards,
  } = usePurchaserStore()

  const [searchStringPlate, setSearchStringPlate] = useState(0)
  const [filter, setFilter] = useState<number>(undefined)
  const [newCards, setNewCards] = useState<IInkoperPlaat[]>([])
  const [buyersCard, setBuyersCard] = useState<IBuyersCard>(undefined)

  const { data: fetchedBuyerCard } = useFetchOne<IBuyersCard>(
    {
      key: filter,
    },
    Entity.BuyersCard,
    open && !!searchStringPlate
  )

  const handleSubmit = async () => {
    if (addEntity.isLoading) return Promise.resolve()

    if (newCards.length === 0) {
      resetState()
      close()
      return Promise.resolve()
    }

    try {
      const promises: Promise<IInkoperPlaat>[] = []
      const temp = [
        ...newCards.filter(
          (obj, index) =>
            newCards.findIndex(
              item =>
                item.koperPlaatNummer === obj.koperPlaatNummer &&
                item.inkoperIdentificatie === obj.inkoperIdentificatie &&
                item.vestigingCode === obj.vestigingCode
            ) === index
        ),
      ]
      temp.forEach(card => {
        const promise = addEntity.mutateAsync(card)
        promises.push(promise)
      })
      await Promise.all(promises)
      resetState()
      close()
    } catch (error) {
      SnackbarUtils.error(`${error}`)
    }
  }

  const handleCancel = useCallback(() => {
    resetState()
    cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetState = () => {
    setMustRefetchPurchaser(true)
    setMustRefetchPurchaserCards(true)
    setNewCards([])
    setSearchStringPlate(0)
    setFilter(undefined)
  }

  const handleFilterBuyerNumber = (
    evt: ChangeEvent<HTMLInputElement>
  ): void => {
    setSearchStringPlate(Number(evt.target.value))
  }

  const handleUserKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      setFilter(searchStringPlate)
    }
  }

  const handleSearchButtonClick = (event: MouseEvent) => {
    event.preventDefault()
    setFilter(searchStringPlate)
  }

  const handleCardChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (searchStringPlate === 0) return
    if (target.checked) {
      const newCard: IInkoperPlaat = {
        inkoperIdentificatie: purchaser.inkoperIdentificatie,
        koperPlaatNummer: buyersCard.buyersCardId,
        mutatieDatumTijd: new Date(),
        mutatieGebruiker: userId,
        vestigingCode: target.name as Vestiging,
      }
      setNewCards([...newCards, newCard])
    } else {
      setNewCards(
        newCards.filter(c => c.vestigingCode !== (target.name as Vestiging))
      )
    }
  }

  useEffect(() => {
    if (fetchedBuyerCard) {
      setBuyersCard(fetchedBuyerCard)
    }
    return () => {
      setBuyersCard(undefined)
      setSearchStringPlate(undefined)
    }
  }, [fetchedBuyerCard, setSearchStringPlate])

  return (
    <Dialog open onClose={handleCancel}>
      <DialogTitle>
        <Typography variant={'h5'} style={{ fontSize: '1rem' }}>
          {t('purchaser.purchaserCard.addDialog.title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          component={'form'}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            boxSizing: 'content-box',
            marginTop: ThemeConfig.spacing.s,
            marginRight: ThemeConfig.spacing.xs * 0.5,
          }}
        >
          <TextField
            id='koperplaatnummer'
            name='koperplaatnummer'
            fullWidth
            placeholder={t('buyersCards.plateNumber')}
            onChange={handleFilterBuyerNumber}
            onKeyDown={handleUserKeyPress}
            sx={{
              [`& ${inputBaseClasses.input}`]: {
                marginLeft: ThemeConfig.spacing.s,
              },
              height: ELEMENT_HEIGHT,
            }}
          />
          <Button
            variant='contained'
            onClick={handleSearchButtonClick}
            sx={{
              boxSizing: 'border-box',
              height: ELEMENT_HEIGHT,
              marginLeft: '0.5rem',
            }}
          >
            {t('common.search')}
          </Button>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: '16px',
            minHeight: '200px',
          }}
          direction={'column'}
        >
          {buyersCard?.aalsmeer && (
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={buyersCard.aalsmeer}
                    name='aalsmeer'
                    onChange={handleCardChange}
                  />
                }
                label='Aalsmeer'
              />
            </Grid>
          )}
          {buyersCard?.naaldwijk && (
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={buyersCard.naaldwijk}
                    name='naaldwijk'
                    onChange={handleCardChange}
                  />
                }
                label='Naaldwijk'
              />
            </Grid>
          )}
          {buyersCard?.rijnsburg && (
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={buyersCard.rijnsburg}
                    name='rijnsburg'
                    onChange={handleCardChange}
                  />
                }
                label='Rijnsburg'
              />
            </Grid>
          )}
          {buyersCard?.eelde && (
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={buyersCard.eelde}
                    name='eelde'
                    onChange={handleCardChange}
                  />
                }
                label='Eelde'
              />
            </Grid>
          )}
        </Grid>
        <DialogActions
          sx={{
            width: '100%',
            marginX: 0,
            flex: 1,
            justifyContent: 'flex-end',
          }}
        >
          <Button variant='outlined' onClick={handleCancel}>
            {t('common.cancel')}
          </Button>
          <Button variant='contained' onClick={handleSubmit}>
            {t('common.ok')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
