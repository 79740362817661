import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

type StoreState = {
  isPurchasersFilterInitialized: boolean
  mustRefreshData: boolean
  purchasersFilter: string | undefined
}

type Actions = {
  /* store */
  resetPurchasersFilterState: () => void
  setMustRefreshData: (input: boolean) => void
  setPurchasersFilter: (filter: string | undefined) => void
  setPurchasersFilterInitialized: (input: boolean) => void
}

const storeName = 'PurchasersFilterStore'

const initialStoreState: StoreState = {
  isPurchasersFilterInitialized: false,
  mustRefreshData: true,
  purchasersFilter: undefined,
}
type StoreType = StoreState & Actions

export const usePurchasersFilterStore = create<StoreType>()(
  devtools(
    persist(
      (set, get: () => StoreType) => ({
        ...initialStoreState,
        resetPurchasersFilterState: () => {
          const prevInit = get().isPurchasersFilterInitialized
          return set(
            () => ({
              ...initialStoreState,
              // once true is always true
              isPurchasersFilterInitialized: prevInit,
            }),
            false, // replace or not replace - that is the question!
            'resetPurchasersFilterState'
          )
        },
        setMustRefreshData: (input: boolean) => {
          set(
            (state: StoreType) => ({
              ...state,
              mustRefreshData: input,
            }),
            false,
            'setMustRefreshData'
          )
        },
        setPurchasersFilter: (filter: string | undefined) => {
          set(
            (state: StoreType) => ({
              ...state,
              purchasersFilter: filter ?? undefined,
            }),
            false,
            'setPurchasersFilter'
          )
        },
        setPurchasersFilterInitialized: (input: boolean) => {
          set(
            (state: StoreType) => ({
              ...state,
              isPurchasersFilterInitialized: input,
            }),
            false,
            'setPurchasersFilterInitialized'
          )
        },
      }),
      {
        name: 'purchasers-fiter-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)
