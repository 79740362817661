import type {
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridSortModel,
  GridValueFormatterParams,
} from '@mui/x-data-grid-premium'

import { defaultPaginationModel, defaultSortModel } from 'src/common/constants'
import type { GridDefaults, GridLocalStorageState } from 'src/common/types'
import { getLocalItem } from 'src/common/utils'

export function calcNewPaginationModel(
  prevModel: GridPaginationModel,
  nextModel: GridPaginationModel
): GridPaginationModel {
  if (prevModel.pageSize === nextModel.pageSize) {
    return nextModel
  }
  const firstRowIndex = prevModel.page * prevModel.pageSize
  const newPage = Math.ceil((firstRowIndex + 1) / nextModel.pageSize) - 1
  nextModel.page = newPage
  return nextModel
}

export type ReorderParams = {
  fields: string[]
  fieldToSwap: string
  targetIndex: number
}

export const swapColumns = ({
  fields,
  fieldToSwap,
  targetIndex,
}: ReorderParams): string[] => {
  const updatedColumns = [...fields]
  const swapIndex = updatedColumns.findIndex(col => col === fieldToSwap)
  const column = updatedColumns.splice(swapIndex, 1)[0]
  updatedColumns.splice(targetIndex, 0, column)
  return updatedColumns
}

export const updateOrderModel = (
  visible: string[],
  oldOrdered: string[]
): string[] => {
  const newOrdered = oldOrdered.filter(
    col => Boolean(col) && visible.includes(col)
  )
  Object.keys(visible).forEach(key => {
    if (!newOrdered.includes(key)) {
      newOrdered.push(key)
    }
  })
  Object.keys(newOrdered).forEach(key => {
    if (!visible[key as keyof typeof visible]) {
      newOrdered.splice(newOrdered.indexOf(key), 1)
    }
  })
  return newOrdered
}

export const getCurrentPaginationModel = (
  cacheKey: string
): GridPaginationModel =>
  getLocalItem<GridLocalStorageState>(cacheKey)?.pagination?.paginationModel ||
  defaultPaginationModel

export const getCurrentSortModel = (cacheKey: string): GridSortModel =>
  getLocalItem<GridLocalStorageState>(cacheKey)?.sorting?.sortModel ||
  defaultSortModel

export const getCurrentColumnVisibilityModel = (
  gridDefaults: GridDefaults
): GridColumnVisibilityModel => {
  const defaultColumnVisibilityModel =
    gridDefaults.initialState.columns.columnVisibilityModel
  return {
    ...defaultColumnVisibilityModel,
    ...getLocalItem<GridLocalStorageState>(gridDefaults.CACHE_KEY)?.columns
      ?.columnVisibilityModel,
  }
}

export const getCurrentOrderedFieldsModel = (
  gridDefaults: GridDefaults
): string[] => {
  let allVisibleColumnFields = Object.keys(
    getLocalItem<GridLocalStorageState>(gridDefaults.CACHE_KEY)?.columns
      ?.columnVisibilityModel ?? {}
  )
  if (allVisibleColumnFields.length === 0) {
    allVisibleColumnFields = Object.keys(
      gridDefaults.initialState.columns.columnVisibilityModel
    )
  }
  const savedOrderedFieldsModel =
    getLocalItem<GridLocalStorageState>(gridDefaults.CACHE_KEY)?.columns
      ?.orderedFields ?? []
  if (savedOrderedFieldsModel.length === 0) {
    return allVisibleColumnFields
  }
  return updateOrderModel(allVisibleColumnFields, savedOrderedFieldsModel)
}

export const formatNumber = (row: GridValueFormatterParams): string => {
  if (!row?.value) {
    return ''
  }
  return row.value.toString().split('.').join('')
}

export const toPascalCase = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1)

export const toCamelCase = (str: string): string =>
  str.charAt(0).toLowerCase() + str.slice(1)

export const isNumberEmpty = (input?: number): boolean =>
  input === 0 || input === undefined || input === null || isNaN(input)

export const noOp = (): void => {
  // nothing to see - use it as a placeholder function
}
