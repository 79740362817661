import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  Button,
  FormControl,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material'

import { ADD_PATH } from 'src/common/constants'
import { useFilter } from 'src/common/hooks'
import {
  LabelBooleanDropdown,
  LabelDropdownText,
  ThemeConfig,
  useGridStore,
} from 'src/common/index'
import { IPurchaserFilterInput } from 'src/common/interfaces/interfaces'
import { useUserStore } from 'src/common/stores/UserStore'
import { constructFilterString } from 'src/common/utils/FilterFunctions'

import { ROOT_PATH, SourceValues, defaultFilter } from '../constants'
import {
  generateFilterString,
  isInputValid,
  replaceEmptyValueWithUndefined,
} from '../lib'
import { usePurchasersFilterStore } from '../stores'
import { FilterProps } from '../types'

export const PurchasersFilter: FC<FilterProps> = ({ resetFilter }) => {
  const keyPurchasersFilterValue = 'PurchasersFilter-values'
  const { user } = useUserStore()
  const { setShouldPaginationBeReset } = useGridStore()
  const {
    setPurchasersFilter,
    setPurchasersFilterInitialized,
    resetPurchasersFilterState,
  } = usePurchasersFilterStore()

  const setFilterString = useCallback(
    (newValues: IPurchaserFilterInput): void => {
      const newFilter = constructFilterString(newValues, generateFilterString)
      setPurchasersFilter(newFilter)
    },
    [setPurchasersFilter]
  )

  /*
  Hooks
  */
  const { t } = useTranslation()

  const { filter, clear, handleChange, handleRequest } =
    useFilter<IPurchaserFilterInput>({
      defaultFilter,
      resetFilter,
      setFilterString,
      cacheKey: keyPurchasersFilterValue,
    })

  const handleInputChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = target
      if (!isInputValid(name, value)) return
      const input = name === 'koperNummer' ? Number(value) : value

      handleChange(
        name as keyof IPurchaserFilterInput,
        replaceEmptyValueWithUndefined(input)
      )
    },
    [handleChange]
  )

  const handleClear = useCallback(() => {
    resetFilter()
    resetPurchasersFilterState()
    clear()
    setFilterString(defaultFilter)
  }, [clear, resetFilter, resetPurchasersFilterState, setFilterString])

  const handleSearch = (event: MouseEvent<HTMLButtonElement>) => {
    setFilterString(filter)
    handleRequest(event)
    setShouldPaginationBeReset(true)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleRequest(event)
      setShouldPaginationBeReset(true)
    }
  }

  const handleInputChangeSelect = ({ target }: SelectChangeEvent<string>) => {
    const { name, value } = target
    const isDefined = ['true', 'false'].includes(value)
    const isTrue = isDefined && value === 'true'
    if (isDefined) {
      handleChange(name as keyof IPurchaserFilterInput, isTrue)
    } else {
      handleChange(name as keyof IPurchaserFilterInput, undefined)
    }
  }

  /*
  Effects
  */
  useEffect(() => {
    setFilterString(filter)
    setPurchasersFilterInitialized(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * render
   */
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'end'}
      minHeight={ThemeConfig.spacing.xl * 4.9}
      marginBottom={ThemeConfig.spacing.sm}
    >
      <Stack
        direction={'row'}
        justifyContent={'justify-start'}
        gap={ThemeConfig.spacing.s}
        alignItems={'end'}
      >
        <FormControl fullWidth>
          <TextField
            label={t('purchaser.purchaserIdentification')}
            name='inkoperIdentificatie'
            value={filter.inkoperIdentificatie ?? ''}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label={t('purchaser.buyerNumber')}
            name='koperNummer'
            value={filter.koperNummer ?? ''}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label={t('purchaser.buyerName')}
            name='koperNaam'
            value={filter.koperNaam ?? ''}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label={t('buyersCards.plateNumber')}
            name='inkoperPlatenLijst'
            value={filter.inkoperPlatenLijst ?? ''}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
        </FormControl>
        <LabelDropdownText
          label={t('purchaser.sourceCode')}
          labelVisible
          values={[
            { name: t('purchaser.all'), code: undefined },
            { name: SourceValues.FDY, code: 'FDY' },
            { name: SourceValues.RRU, code: 'RRU' },
          ]}
          value={filter.sourceCode}
          fieldName='sourceCode'
          onChange={({ target }) => handleChange('sourceCode', target.value)}
        />
        <LabelBooleanDropdown
          label={t('buyersCards.blocked')}
          onChange={handleInputChangeSelect}
          inputValue={filter.blokkeerIndicatie}
          fieldname={'blokkeerIndicatie'}
          labelVisible
        />
        <LabelBooleanDropdown
          label={t('buyersCards.expired')}
          onChange={handleInputChangeSelect}
          inputValue={filter.vervalIndicatie}
          fieldname={'vervalIndicatie'}
          labelVisible
        />
      </Stack>

      <Stack
        direction={'row'}
        justifyContent={'justify-end'}
        alignItems={'end'}
        gap={ThemeConfig.spacing.s}
      >
        <Button
          variant='outlined'
          onClick={handleClear}
          sx={{
            height: ThemeConfig.spacing.xl * 4.9,
            mr: ThemeConfig.spacing.sm,
          }}
        >
          {t('common.clear')}
        </Button>
        {user.isApplicationManager || user.isContractBeheerder ? (
          <Button
            variant='contained'
            component={Link}
            to={`/dashboard/${ROOT_PATH}/${ADD_PATH}`}
            sx={{
              height: ThemeConfig.spacing.xl * 4.9,
              mr: ThemeConfig.spacing.sm,
            }}
          >
            {t('common.add')}
          </Button>
        ) : null}
        <Button
          variant='contained'
          type='submit'
          onClick={handleSearch}
          sx={{ height: ThemeConfig.spacing.xl * 4.9 }}
        >
          {t('common.search')}
        </Button>
      </Stack>
    </Stack>
  )
}
