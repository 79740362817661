import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-GB'
import nlLocale from 'date-fns/locale/nl'

import { FC, useEffect, useState } from 'react'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import i18n, { SupportedLanguage } from 'src/i18n/index'

import { getLanguage } from '../services'

export const LocalizationProvider: FC = ({ children }) => {
  const localeMap = {
    de: deLocale,
    en: enLocale,
    nl: nlLocale,
  }
  const [locale, setLocale] = useState<SupportedLanguage>(
    getLanguage().slice(0, 2) as SupportedLanguage
  )

  const selectLocale = (newLocale: SupportedLanguage) => {
    setLocale(newLocale)
  }

  useEffect(() => {
    const newLocale = i18n.language.slice(0, 2) as SupportedLanguage
    selectLocale(newLocale)
    i18n.changeLanguage(newLocale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  return (
    <MuiLocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localeMap[locale]}
    >
      {children}
    </MuiLocalizationProvider>
  )
}
