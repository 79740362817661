import {
  FormControl,
  FormLabel,
  Grid,
  SxProps,
  inputBaseClasses,
  inputClasses,
  svgIconClasses,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import theme from '@rfh-core/theme'

import { noOp } from 'src/common/index'

type LabelDateProps = {
  disabled?: boolean
  fieldName: string
  label: string
  value?: Date
  flowDirection: 'row' | 'column' | 'column-reverse' | 'row-reverse'
  onChange?: (date: Date) => void
  sx?: SxProps
}

export default function LabelDate({
  disabled = false,
  fieldName,
  label,
  value = null,
  flowDirection,
  onChange = noOp,
  sx,
}: Readonly<LabelDateProps>): JSX.Element {
  return (
    <Grid container direction={flowDirection}>
      <Grid item justifyContent={'flex-start'} alignItems={'start'}>
        <FormControl sx={{ ml: 0 }}>
          <FormLabel
            htmlFor={`id-${fieldName}`}
            sx={{
              color: theme.rfhColors.black,
              whiteSpace: 'nowrap',
              minWidth: 170,
              textAlign: 'start',
              ...sx,
            }}
          >
            {label}
          </FormLabel>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <DatePicker
            aria-label={`date picker for ${label.toLowerCase()}`}
            defaultValue={null}
            disabled={disabled}
            value={value}
            onChange={onChange}
            slotProps={{
              textField: {
                sx: {
                  bgcolor: theme.rfhColors.white,
                  [`& ${inputClasses.root}`]: {
                    paddingX: 1,
                    [`& .${svgIconClasses.root}`]: {
                      color: theme.rfhColors.dutchOrange,
                    },
                  },
                  [`& ${inputBaseClasses.root}`]: {
                    border: `1px solid ${theme.rfhColors.grey[200]} !important`,
                  },
                },
              },
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}
