// eslint-disable-next-line
import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Box, Button, Container } from '@mui/material'
import { EmptyState, EmptyStateIcon } from '@rfh-core/components'

const NotFound: FC = (): ReactElement => {
  const history = useHistory()
  const { t } = useTranslation()

  const gotoHome = (): void => {
    history.push('/dashboard')
  }

  return (
    <Container
      maxWidth='xl'
      sx={{
        height: 'calc(100vh - 72px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '800px',
          margin: '0 auto',
        }}
        id='cy-not-found'
      >
        <EmptyState
          header={t('notFound.title')}
          subheader={<p>{t('notFound.subtitle')}</p>}
          graphic={<EmptyStateIcon />}
          bottomComponent={
            <Button variant='contained' onClick={gotoHome}>
              {t('notFound.gotoHome')}
            </Button>
          }
        />
      </Box>
    </Container>
  )
}

export default NotFound
