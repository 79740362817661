import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl, InputAdornment, TextField, debounce } from '@mui/material'

import { IKoper } from 'src/common/services/client'
import { BuyersCardsDialogAvailableBuyers } from 'src/features/buyerscards/components'

import { ThemeConfig } from '../config'
import { Entity, useFetchOne } from '../hooks'
import { useUserStore } from '../stores/UserStore'

type CustomerInputProps = {
  changeCustomerNumber: (newNumber: number) => void
  changeValid: (newValue: boolean) => void
  showOnlyAvailableBuyers: boolean
  disabled?: boolean
  sx?: any
  value?: number
}

const CustomerInput: FC<CustomerInputProps> = ({
  disabled,
  changeCustomerNumber,
  showOnlyAvailableBuyers,
  changeValid,
  value,
  sx,
}) => {
  /*
   * State
   */
  const [customerNumber, setCustomerNumber] = useState<number>(0)
  const [numberToSearch, setNumberToSearch] = useState<number>(undefined)
  /*
   * Hooks
   */
  const { t } = useTranslation()
  const { user } = useUserStore()
  const { data: koper, isError } = useFetchOne<IKoper>(
    { key: numberToSearch },
    Entity.IKoper
  )

  /*
   * Methods
   */
  const changeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value ?? 0)
    if (newValue < 999999999 && newValue >= 0) {
      setCustomerNumber(newValue)
      bouncedNewInput(newValue)
    }
  }

  const selectValue = (event: any) => {
    const newValue = Number(event.target.value ?? 0)
    if (newValue < 999999999 && newValue >= 0) {
      setCustomerNumber(newValue)
      newInput(newValue)
    }
  }

  const newInput = (newValue: number) => {
    if (newValue) {
      setNumberToSearch(newValue)
      changeCustomerNumber(newValue)
    } else {
      setCustomerNumber(0)
      setNumberToSearch(undefined)
      changeCustomerNumber(undefined)
      changeValid(false)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bouncedNewInput = useCallback(debounce(newInput, 1500), [
    user.accessToken,
  ])

  const getHelperText = (): string => {
    if (value && koper) {
      return koper?.kopernaam
    }
    return ''
  }

  useEffect(() => {
    if (customerNumber === 0) {
      setCustomerNumber(value)
    }
    newInput(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  /*
   * Render
   */
  return (
    <FormControl sx={sx} fullWidth>
      <TextField
        label={t('customerInput.customerNumber')}
        id='klantnummer'
        name='klantnummer'
        disabled={disabled}
        error={isError}
        type='number'
        value={customerNumber !== 0 ? customerNumber : ''}
        placeholder={t('customerInput.placeholder')}
        onChange={changeValue}
        helperText={getHelperText()}
        InputProps={{
          endAdornment: !disabled && (
            <InputAdornment position='end'>
              <BuyersCardsDialogAvailableBuyers
                onChangeBuyer={selectValue}
                showOnlyAvailableBuyers={showOnlyAvailableBuyers}
              />
            </InputAdornment>
          ),
        }}
        FormHelperTextProps={{
          sx: {
            position: 'absolute',
            top: ThemeConfig.spacing.xl * 10,
          },
        }}
      />
    </FormControl>
  )
}

export default CustomerInput
