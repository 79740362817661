import { FC } from 'react'
import { Route, Switch } from 'react-router-dom'

import { SecureRoute } from '@okta/okta-react'

import { BuyersCards, ManageBuyersCards } from 'src/features/buyerscards'
import { ManagePurchaser, Purchasers } from 'src/features/purchasers'
import BuyersCardsHistory from 'src/views/buyersCardsHistory/BuyersCardsHistory'
import HomePage from 'src/views/homepage/HomePage'
import NotFound from 'src/views/notfound/NotFound'
import { ProfilePage } from 'src/views/profilePage'

import { AccountManagementRoute } from '../guards/AccountManagementRoute'
import { ContractbeheerderRoute } from '../guards/ContractbeheerderRoute'

export const InternalRoutes: FC = () => (
  <Switch>
    <SecureRoute path='/dashboard' exact component={HomePage} />
    <SecureRoute
      path='/dashboard/buyerscard/:cardId'
      exact
      component={BuyersCards}
    />
    <AccountManagementRoute
      path='/dashboard/buyerscards/add'
      exact
      component={ManageBuyersCards}
    />
    <AccountManagementRoute
      path='/dashboard/buyerscards/:cardId/edit'
      exact
      component={ManageBuyersCards}
    />
    <SecureRoute path='/dashboard/buyerscards' component={BuyersCards} />
    <SecureRoute
      path='/dashboard/buyerscards-history'
      component={BuyersCardsHistory}
    />
    <SecureRoute path='/dashboard/purchasers' exact component={Purchasers} />
    <ContractbeheerderRoute
      path='/dashboard/purchasers/add'
      exact
      component={ManagePurchaser}
    />
    <ContractbeheerderRoute
      path='/dashboard/purchasers/:id'
      exact
      component={ManagePurchaser}
    />
    <SecureRoute path='/dashboard/profile' component={ProfilePage} />
    <Route component={NotFound} />
  </Switch>
)
