import {
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'

import { getLocalItem, updateLocalItem } from 'src/common/utils/OfflineManager'

interface UseFilterProps<T> {
  defaultFilter: T
  resetFilter: () => void
  setFilterString: (input?: T) => void
  cacheKey: string
}

type UseFilterResponseType<T> = {
  filter: T
  setFilter: (input: T) => void
  handleChange: <K extends keyof T, V extends T[K]>(name: K, value: V) => void
  clear: () => void
  handleRequest: (
    event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLInputElement>
  ) => void
}

export const useFilter = <T>({
  defaultFilter,
  resetFilter,
  setFilterString,
  cacheKey,
}: UseFilterProps<T>): UseFilterResponseType<T> => {
  const [filter, setFilter] = useState<T>(
    () => getLocalItem<T>(cacheKey) ?? defaultFilter
  )

  const handleChange = useCallback(
    <K extends keyof T, V extends T[K]>(name: K, value: V): void => {
      const newValues = { ...filter, [name]: value }
      setFilter(newValues)
      updateLocalItem<T>(cacheKey, newValues)
    },
    [cacheKey, filter]
  )

  const clear = useCallback(() => {
    setFilter(defaultFilter)
    updateLocalItem<T>(cacheKey, defaultFilter)
    resetFilter()
  }, [cacheKey, defaultFilter, resetFilter])

  const handleRequest = useCallback(
    (
      event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLInputElement>
    ): void => {
      event.preventDefault()
      setFilterString(filter)
    },
    [filter, setFilterString]
  )

  return useMemo(
    () => ({ filter, setFilter, handleChange, clear, handleRequest }),
    [filter, setFilter, handleChange, clear, handleRequest]
  )
}
