import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { useApiClient } from 'src/common/providers'
import SnackbarUtils from 'src/common/utils/SnackbarUtils'

import { getFuncByEntity } from './own/lib.map'
import {
  ApiClientUpdateByKeyFuncType,
  EntityType,
  FuncSort,
} from './own/lib.resources'

type updateInput<T> = {
  key: number
  body: T
}

export const useUpdateEntityByKey = <T>(
  entity: EntityType
): UseMutationResult<void, unknown, updateInput<T>, unknown> => {
  const { apiClient } = useApiClient()
  const func = getFuncByEntity(
    FuncSort.update,
    entity
  ) as ApiClientUpdateByKeyFuncType
  const queryClient = useQueryClient()
  const result = useMutation({
    mutationKey: [FuncSort.update, entity],
    mutationFn: ({ key, body }: updateInput<T>) =>
      apiClient[func](key, body as any),
    onSettled: () => {
      queryClient.invalidateQueries()
    },
  })

  if (result.isError) {
    SnackbarUtils.error(`${result.error}`)
  }

  return result
}
