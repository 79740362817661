import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

import { Entity, useFetchMany, usePathname } from 'src/common/hooks'
import { UpsertMode } from 'src/common/index'
import { IFloridayUser } from 'src/common/services/client'
import {
  constructFilterString,
  generateDefaultFilterString,
} from 'src/common/utils/FilterFunctions'

import { useBuyerStore } from '../stores'
import { FloridayUserItem } from './FloridayUserItem'

type FloridaySearchDialogProps = {
  open: boolean
  setOpen: (input: boolean) => void
  onChange: (evt: IFloridayUser) => void
}

type inputProps = {
  email: string
}

type SearchInput = {
  organizationGln: string
  alternateId: string
}

export const FloridaySearchDialog: FC<FloridaySearchDialogProps> = ({
  open,
  setOpen,
  onChange,
}) => {
  const { t } = useTranslation()
  const { handleSubmit } = useForm<inputProps>()
  const [searchString, setSearchString] = useState('')
  const { upsertMode } = usePathname('purchasers')
  const { buyerGlnCode } = useBuyerStore()
  const [filter, setFilter] = useState<string | undefined>(undefined)

  const { data: users, isLoading } = useFetchMany<IFloridayUser>(
    {
      count: true,
      expand: 'Koper',
      filter,
    },
    Entity.FloridayUser,
    open
  )

  const onSubmit = () => {
    if (isLoading) return Promise.resolve()
    setFilter(
      constructFilterString<SearchInput>(
        {
          alternateId: searchString,
          organizationGln:
            upsertMode === UpsertMode.edit
              ? String(buyerGlnCode ?? '')
              : undefined,
        },
        generateDefaultFilterString
      )
    )
  }

  const handleUserKeyPress = (evt: any) => {
    if (evt.key === 'Enter' && !evt.shiftKey) {
      evt.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  const handleFilter = (evt: any): void => {
    setSearchString(evt.target.value)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleSelectedItem = (evt: IFloridayUser) => {
    onChange(evt)
    setOpen(false)
  }

  useEffect(() => {
    if (upsertMode === UpsertMode.edit && buyerGlnCode > 0) {
      setFilter(
        constructFilterString(
          {
            organizationGln: String(buyerGlnCode ?? ''),
          },
          generateDefaultFilterString
        )
      )
    }
  }, [buyerGlnCode, open, upsertMode])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent
        sx={{
          width: '600px',
          maxWidth: '600px',
        }}
      >
        <Typography style={{ marginBottom: '16px' }} variant={'h5'}>
          {t('buyersCards.addBuyersCards.zoekKopers')}
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <TextField
            fullWidth
            onChange={handleFilter}
            onKeyDown={handleUserKeyPress}
          />
          <Button type='submit' variant='contained' disabled={isLoading}>
            {t('common.search')}
          </Button>
        </form>
        <Table
          style={{
            minHeight: '100px',
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  textAlign: 'left',
                  verticalAlign: 'top',
                }}
              >
                {t('purchaser.floridayUserName')}
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'left',
                  verticalAlign: 'top',
                }}
              >
                {t('purchaser.buyerName')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={2}>{t('common.loading')}</TableCell>
              </TableRow>
            )}
            {!users?.records.length && (
              <TableRow>
                <TableCell colSpan={2}>{t('common.noResults')}</TableCell>
              </TableRow>
            )}
            {users?.records.map(value => (
              <FloridayUserItem
                key={value.technicalId}
                user={value}
                onClick={handleSelectedItem}
              />
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  )
}
