import { Typography, styled } from '@mui/material'

import { ThemeConfig } from 'src/common/config/SpacingConfig'

export const StyledRfhTypographyTitle = styled(Typography)(() => ({}))

export const StyledRfhTypographyContent = styled(Typography)(() => ({
  display: 'flex',
  padding: 0,
  justifyContent: 'space-between',
  marginBottom: ThemeConfig.spacing.xl,
  overflowWrap: 'anywhere',
}))
