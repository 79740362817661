import { ISearchFormInput, generateDefaultFilterString } from 'src/common/index'

export const generateFilterString = (
  key: keyof ISearchFormInput,
  value: ISearchFormInput[keyof ISearchFormInput],
  filterRules: string[]
): string[] => {
  let filterRule = ''

  if (key === 'vestiging' && value !== '') {
    filterRule = `${value} eq true`
    filterRules.push(filterRule)
  }
  if (typeof value === 'boolean' && value !== undefined) {
    filterRule = `${key} eq ${value}`
    filterRules.push(filterRule)
  } else if (key !== 'vestiging') {
    filterRules = generateDefaultFilterString(key, value as any, filterRules)
  }

  return filterRules
}
