import { FormEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Box, Button, Grid, Typography } from '@mui/material'

import { useStore } from 'zustand'

import { ThemeConfig } from 'src/common/config/SpacingConfig'
import {
  Entity,
  useAddEntity,
  usePathname,
  useUpdateEntity,
  useUpdateEntityByKey,
} from 'src/common/hooks'
import { UpsertMode, snackbarUtils } from 'src/common/index'
import {
  IFloridayUser,
  IInkoper,
  IInkoperView,
  IMigratieInkoper,
  MigratieInkoper,
} from 'src/common/services/client'
import { utcNever } from 'src/common/utils/DateFunctions'

import { removeExtraIInkoperViewProperties } from '../../lib'
import {
  useBuyerStore,
  useCardRowsStore,
  usePurchaserStore,
} from '../../stores'
import { FloridaySearchDialog } from '../FloridaySearchDialog'
import BlockingFields from './BlockingFields'
import GeneralFields from './GeneralFields'

type PurchaserDetailsProps = {
  onGoBack: () => void
}

export default function PurchaserDetails({
  onGoBack,
}: Readonly<PurchaserDetailsProps>): JSX.Element {
  const { t } = useTranslation()
  const history = useHistory()
  const { upsertMode } = usePathname('purchasers')
  const addEntity = useAddEntity<IInkoperView>(Entity.IInkoperView)
  const updateEntityByKey = useUpdateEntityByKey<IInkoper>(Entity.IInkoper)
  const migratePurchaser = useUpdateEntity<IMigratieInkoper>(
    Entity.IInkoperView
  )
  const {
    isPurchaserDirty,
    purchaser,
    resetPurchaserState,
    setMustRefetchPurchaser,
    updatePurchaser,
    userId,
    openFloridaySearch,
    setOpenFloridaySearch,
  } = usePurchaserStore()
  const { buyerName, resetBuyerState, setBuyerName, setBuyerNumber } =
    useBuyerStore()
  const resetCardRowsState = useStore(
    useCardRowsStore,
    state => state.resetCardRowsState
  )

  const [busyUpserting, setBusyUpserting] = useState(false)

  const handleClickOpen = () => {
    setOpenFloridaySearch(true)
  }

  const isFormFilled = useCallback(
    () =>
      Boolean(purchaser?.inkoperIdentificatie) &&
      buyerName && // valid buyer
      purchaser?.koperNummer > 0 &&
      Boolean(purchaser?.locatieCode),
    [purchaser, buyerName]
  )

  const handleGoBack = () => {
    resetPurchaserState()
    resetCardRowsState()
    resetBuyerState()
    onGoBack()
  }

  const handleFloridaySelection = (floridayUser: IFloridayUser) => {
    if (floridayUser?.koper) {
      setBuyerName(floridayUser?.koper?.kopernaam)
      setBuyerNumber(floridayUser?.koper?.kopernummer)
      if (upsertMode === UpsertMode.add) {
        updatePurchaser({
          inkoperIdentificatie: floridayUser.alternateId,
          koperNaam: floridayUser.koper.kopernaam,
          koperNummer: floridayUser.koper.kopernummer,
          sourceCode: 'FDY',
        })
      } else {
        updatePurchaser({
          inkoperIdentificatie: floridayUser.alternateId,
          koperNaam: floridayUser.koper.kopernaam,
          koperNummer: floridayUser.koper.kopernummer,
          alias: purchaser.inkoperIdentificatie,
          sourceCode: 'FDY',
        })
        const toMigrate = {
          alias: purchaser.inkoperIdentificatie,
          inkoperId: purchaser.inkoperID,
          inkoperIdentificatie: floridayUser.alternateId,
        } as MigratieInkoper
        migratePurchaser.mutate(toMigrate)
      }
    }
  }

  const handleSubmitPurchaser = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      // avoid concurrent submits or no inkoperID when editing
      if (
        busyUpserting ||
        (upsertMode === 'EDIT' && !purchaser?.inkoperID) ||
        addEntity.isLoading ||
        updateEntityByKey.isLoading
      ) {
        return Promise.resolve()
      }

      setBusyUpserting(true)
      const purchaserId = purchaser?.inkoperID ?? 0

      // remove inkoperPlatenLijst, koperNaam, locatieOmschrijving
      const purchaserToUpsert: IInkoper =
        removeExtraIInkoperViewProperties(purchaser)
      purchaserToUpsert.inkoperPlaten = []
      purchaserToUpsert.mutatieDatumTijd = new Date()
      purchaserToUpsert.mutatieGebruiker = userId
      purchaserToUpsert.blokkerenDatumTijd = purchaser?.blokkerenDatumTijd
      purchaserToUpsert.vrijgaveDatumTijd = purchaser?.vrijgaveDatumTijd
      purchaserToUpsert.blokkeerRedenCode =
        purchaser?.blokkeerRedenCode || undefined
      purchaserToUpsert.opmerking = purchaser?.opmerking
      updatePurchaser({
        mutatieDatumTijd: new Date(),
        mutatieGebruiker: userId,
      })

      try {
        if (upsertMode === 'ADD') {
          purchaserToUpsert.inkoperID = purchaserId
          purchaserToUpsert.vervallenDatumTijd = utcNever()
          purchaserToUpsert.aanmaakDatumTijd = new Date()
          purchaserToUpsert.aanmaakGebruiker = userId
          updatePurchaser(purchaserToUpsert)
          const addedPurchaser = (await addEntity.mutateAsync(
            purchaserToUpsert
          )) as IInkoper

          if (addedPurchaser?.inkoperID > 0) {
            updatePurchaser(addedPurchaser)
            // go from 'ADD' to 'EDIT' upsertMode
            snackbarUtils.success(t('purchaser.upsert.added'))
            history.replace(`/dashboard/purchasers/${addedPurchaser.inkoperID}`)
          }
        } else {
          /* EDITING */

          await updateEntityByKey.mutateAsync({
            key: purchaserId,
            body: purchaserToUpsert,
          })
          snackbarUtils.success(t('purchaser.upsert.saved'))
        }
      } catch (error) {
        snackbarUtils.error(`${error}`)
      } finally {
        setBusyUpserting(false)
        setMustRefetchPurchaser(true)
      }
    },
    [
      busyUpserting,
      upsertMode,
      purchaser,
      addEntity,
      updateEntityByKey,
      userId,
      updatePurchaser,
      t,
      history,
      setMustRefetchPurchaser,
    ]
  )

  return (
    <>
      <Grid container sx={{ width: '80%' }}>
        <Grid item xs={12}>
          <Typography
            variant='h1'
            style={{
              fontSize: '35px',
              marginTop: `${ThemeConfig.spacing.sm * 8}px`,
              marginBottom: `${ThemeConfig.spacing.sm * 8}px`,
            }}
          >
            {upsertMode === 'ADD'
              ? t('common.titles.purchaserAdd')
              : t('common.titles.purchaser')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            component='form'
            onSubmit={handleSubmitPurchaser}
            sx={{ marginTop: ThemeConfig.spacing.sm, width: '100%' }}
          >
            <Grid
              container
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Grid
                item
                sx={{
                  width: '50%',
                  borderRight: theme => `1px solid ${theme.palette.grey[300]}`,
                  paddingRight: ThemeConfig.spacing.m,
                }}
              >
                <GeneralFields />
              </Grid>
              <Grid
                item
                sx={{
                  width: '50%',
                  paddingLeft: ThemeConfig.spacing.m,
                }}
              >
                <BlockingFields />
              </Grid>
            </Grid>
            {/* buttons */}
            <Grid
              container
              sx={{
                marginTop: ThemeConfig.spacing.sm,
                marginBottom: ThemeConfig.spacing.m,
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
                gap={ThemeConfig.spacing.s}
              >
                <Button
                  variant='outlined'
                  sx={{
                    paddingX: ThemeConfig.spacing.sm,
                  }}
                  onClick={handleGoBack}
                >
                  {t('common.back')}
                </Button>
                {upsertMode === UpsertMode.edit &&
                  purchaser?.sourceCode === 'RRU' && (
                    <Button
                      variant='contained'
                      disabled={busyUpserting}
                      sx={{
                        paddingX: ThemeConfig.spacing.sm,
                      }}
                      onClick={handleClickOpen}
                    >
                      {t('purchaser.migratePurchaser')}
                    </Button>
                  )}
                <Button
                  type='submit'
                  variant='contained'
                  disabled={!isFormFilled() || !isPurchaserDirty()}
                  sx={{
                    paddingX: ThemeConfig.spacing.sm,
                  }}
                >
                  {upsertMode === 'ADD' ? t('common.create') : t('common.save')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <FloridaySearchDialog
        open={openFloridaySearch}
        setOpen={setOpenFloridaySearch}
        onChange={handleFloridaySelection}
      />
    </>
  )
}
