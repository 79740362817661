import { useEffect } from 'react'

import { SelectChangeEvent, selectClasses } from '@mui/material'

import {
  ELEMENT_HEIGHT,
  Entity,
  ILocatie,
  LabelDropdownText,
  useFetchMany,
} from 'src/common'

import { locationToItem } from '../lib'
import { usePurchaserStore } from '../stores'

type LocationDropDownProps = {
  value: string
  name: string
  onChange?: (event: SelectChangeEvent<string>) => void
}
export default function LocationDropDown({
  name,
  value,
  onChange,
}: Readonly<LocationDropDownProps>): JSX.Element {
  const ENABLED = true as const
  const { setLocations } = usePurchaserStore()

  const {
    data: { records: locations },
  } = useFetchMany<ILocatie>(undefined, Entity.ILocatie, ENABLED, {
    staleTime: Infinity,
    suspense: true,
  })

  useEffect(() => {
    setLocations(locations)
  }, [locations, setLocations])

  return (
    <LabelDropdownText
      fieldName={name}
      values={locations.map(locationToItem)}
      value={value.toUpperCase()}
      onChange={onChange}
      sx={{
        [`& .${selectClasses.select}`]: {
          paddingLeft: 'unset',
        },
        maxHeight: ELEMENT_HEIGHT,
        fontSize: '1em',
        fontWeight: 100,
      }}
    />
  )
}
